export const amenitiesList = {
    Close_To_Train: {
      name: "Close to train station",
      icon: "la-train",
    },
    Close_To_Bus: {
      name: "Close to bus station",
      icon: "la-bus",
    },
    Free_Parking: {
      name: "Free parking",
      icon: "la-parking",
    },
    Dog_Friendly: {
      name: "Dog-friendly",
      icon: "la-dog",
    },
    Mentors_Meetings: {
      name: "Mentors meetings",
      icon: "la-chalkboard-teacher",
    },
    Happy_Hour: {
      name: "Happy hour",
      icon: "la-cocktail",
    },
    Soft_Drinks: {
      name: "Soft drinks",
      icon: "la-glass-whiskey",
    },
    Beer: {
      name: "Beer",
      icon: "la-beer",
    },
    Snacks: {
      name: "Snacks",
      icon: "la-cookie",
    },
    Wifi: {
      name: "Wifi",
      icon: "la-wifi",
    },
    Printing_Services: {
      name: "Printing services",
      icon: "la-print",
    },
    Staff: {
      name: "Staff",
      icon: "la-user-tie",
    },
    Showers: {
      name: "Showers",
      icon: "la-shower",
    },
    Open_On_Saturday: {
      name: "Open on Saturday",
      icon: "la-calendar-day",
    },
    Open_On_Friday: {
      name: "Open on Friday",
      icon: "la-calendar-day",
    },
    Open_24_7: {
      name: "Open 24/7",
      icon: "la-clock",
    },
    Gym: {
      name: "Gym",
      icon: "la-dumbbell",
    },
    Swimming_Pool: {
      name: "Swimming pool",
      icon: "la-swimmer",
    },
    Sport_Classes: {
      name: "Sport classes",
      icon: "la-running",
    },
    No_Commitment: {
      name: "No commitment",
      icon: "la-handshake",
    },
    One_Day_Min: {
      name: "1 day min.",
      icon: "la-calendar-day",
    },
    One_Week_Min: {
      name: "1 week min.",
      icon: "la-calendar-week",
    },
    One_Month_Min: {
      name: "1 month min.",
      icon: "la-calendar-alt",
    },
    Two_Months_Min: {
      name: "2 months min.",
      icon: "la-calendar-alt",
    },
    City_Residents_Only: {
      name: "City residents only",
      icon: "la-city",
    },
    Designers_Only: {
      name: "Designers only",
      icon: "la-pencil-ruler",
    },
    Requires_Screening_Interview: {
      name: "Requires a screening interview",
      icon: "la-comments",
    },
    Social_Ventures_Only: {
      name: "Social ventures only",
      icon: "la-users",
    },
    Technological_Ventures_Only: {
      name: "Technological ventures only",
      icon: "la-microchip",
    },
    Worldwide_Access: {
      name: "Worldwide access",
      icon: "la-globe",
    },
    Kitchen: {
      name: "Kitchen",
      icon: "la-utensils",
    },
    Coffee_Machine: {
      name: "Coffee machine",
      icon: "la-coffee",
    },
    Rooftop_Access: {
      name: "Rooftop access",
      icon: "la-building",
    },
    Bike_Racks: {
      name: "Bike racks",
      icon: "la-bicycle",
    },
    Lockers: {
      name: "Lockers",
      icon: "la-lock",
    },
    Air_Conditioning: {
      name: "Air conditioning",
      icon: "la-fan",
    },
    Conference_Rooms: {
      name: "Conference rooms",
      icon: "la-briefcase",
    },
    Outdoor_Area: {
      name: "Outdoor area",
      icon: "la-tree",
    },
    Wheelchair_Accessible: {
      name: "Wheelchair accessible",
      icon: "la-wheelchair",
    },
    Childcare_Services: {
      name: "Childcare services",
      icon: "la-baby",
    },
    Meditation_Room: {
      name: "Meditation room",
      icon: "la-spa",
    },
    Game_Room: {
      name: "Game room",
      icon: "la-gamepad",
    },
    Library: {
      name: "Library",
      icon: "la-book",
    },
    Phone_Booths: {
      name: "Phone booths",
      icon: "la-phone",
    },
    Event_Space: {
      name: "Event space",
      icon: "la-calendar",
    },
    Laundry_Service: {
      name: "Laundry service",
      icon: "la-tshirt",
    },
    Music_Room: {
      name: "Music room",
      icon: "la-music",
    },
  };
  