import { sendTripDetailsToBackend } from "api/trip";
import { GuestsObject } from "components/HeroSearchForm/type";
import { BusinessTripState } from "components/HeroSearchFormSmall/(flight-search-form)/FlightSearchForm";
import moment from 'moment-timezone';
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import converSelectedDateToString from "utils/converSelectedDateToString";
import { validateTripDetails } from "validators/trip.validator";
import GuestsInput from "../GuestsInput";
import LocationInput from "../LocationInput";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { searchFormStore } from "stores/Views/SearchFormStore";
import { observer } from "mobx-react";
interface FlightSearchFormProps {
  onExternalTrigger: boolean;
}
export type TripClassTypes = 'Economy' | 'Business' | 'First';
export type TripTypes = 'roundTrip' | 'oneWay' | "";
export type TripPurposeTypes =
  | 'Attending a Conference or Trade Show'
  | 'Client Meetings'
  | 'Sales and Business Development'
  | 'Training and Professional Development'
  | 'Internal Company Meetings'
  | 'Market Research'
  | 'Site Visits and Inspections'
  | 'Networking Events'
  | 'Looking for Funding/Seeking Investment'
  | 'Other';

const FlightSearchForm: React.FC<FlightSearchFormProps> = ({ onExternalTrigger }) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<"locationPickup" | "locationDropoff" | "dates" | "guests" | "general">("locationPickup");
  const [tripDetails, setTripDetails] = useState<BusinessTripState>({
    flyingFrom: null,
    flyingTo: null,
    tripType: 'roundTrip',
    tripClass: 'Economy',
    purpose: 'Attending a Conference or Trade Show',
    startDate: new Date(),
    endDate: moment().add(5, 'days').toDate(),
  });
  const navigate = useNavigate();

  const [guestInput, setGuestInput] = useState<GuestsObject>({
    guestAdults: 0,
    guestChildren: 0,
    guestInfants: 0,
  });

  const onChangeStartDate = (startDate: Date | null) => {
    setTripDetails(prevDetails => ({
      ...prevDetails,
      startDate: startDate
    }));
  };

  const onChangeEndDate = (endDate: Date | null) => {
    setTripDetails(prevDetails => ({
      ...prevDetails,
      endDate: endDate
    }));
  }

  const onChangeRangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    onChangeStartDate(start);
    onChangeEndDate(end);
  };

  // Listen for the external trigger and call the function
  React.useEffect(() => {
    if (onExternalTrigger) {
      handleSubmit();
    }
  }, [onExternalTrigger]);

  // Function to handle form submission
  const handleSubmit = async () => {
    const validationErrors = validateTripDetails(tripDetails);

    if (validationErrors.length > 0) {
      const errorMessage = validationErrors.join('\n');
      toast.error(errorMessage)
      console.error(validationErrors);
      return;
    }

    // If validation passes, proceed to send data to backend
    try {
      // Replace with your actual API call
      const { data: itinerary } = await sendTripDetailsToBackend(tripDetails);
      searchFormStore.setShowMobileModal(false);

      toast.success('Trip details submitted successfully.')
      navigate(`/trip/${itinerary.id}`);
    } catch (error) {
      // Handle submission error
      console.error('Error submitting trip details:', error);
      toast.error('Something went wrong.')
    }
  };

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">Departure</span>
            <span>{tripDetails.flyingFrom?.description || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Departure"
            defaultValue={tripDetails.flyingFrom?.description}
            onChange={(value) => {
              setTripDetails(prevDetails => ({ ...prevDetails, flyingFrom: value }));
              setFieldNameShow("locationDropoff");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputLocationDropoff = () => {
    const isActive = fieldNameShow === "locationDropoff";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationDropoff")}
          >
            <span className="text-neutral-400">Destination</span>
            <span>{tripDetails.flyingTo?.description || "Location"}</span>
          </button>
        ) : (
          <LocationInput
            headingText="Destination"
            defaultValue={tripDetails.flyingTo?.description}
            onChange={(value) => {
              setTripDetails(prevDetails => ({ ...prevDetails, flyingTo: value }));
              setFieldNameShow("general");
            }}
          />
        )}
      </div>
    );
  };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>
              {tripDetails?.startDate
                ? converSelectedDateToString([tripDetails?.startDate, tripDetails?.endDate])
                : "Add date"}
            </span>
          </button>
        ) : (
          tripDetails?.tripType !== 'oneWay' ? <DatePicker
            selected={tripDetails?.startDate}
            onChange={onChangeRangeDate}
            startDate={tripDetails?.startDate}
            endDate={tripDetails?.endDate}
            selectsRange
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
            minDate={moment().toDate()}
          /> : <DatePicker
            selected={tripDetails?.startDate}
            onChange={(date) => onChangeStartDate(date)}
            monthsShown={2}
            showPopperArrow={false}
            inline
            renderCustomHeader={(p) => (
              <DatePickerCustomHeaderTwoMonth {...p} />
            )}
            renderDayContents={(day, date) => (
              <DatePickerCustomDay dayOfMonth={day} date={date} />
            )}
            minDate={moment().toDate()}
          />
        )}
      </div>
    );
  };

  const renderGenerals = () => {
    const isActive = fieldNameShow === "general";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("general")}
          >
            <span className="text-neutral-400">Flight type</span>
            <span>{`${tripDetails?.tripType === "roundTrip" ? "Round-trip" : "One-way"}, ${tripDetails?.tripClass}`}</span>
          </button>
        ) : (
          <div className="p-5">
            <span className="block font-semibold text-xl sm:text-2xl">
              Flight type
            </span>
            <div className="relative mt-5">
              <div className="flex space-x-2">
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${tripDetails?.tripType === "roundTrip"
                    ? "bg-black shadow-black/10 shadow-lg text-white"
                    : "border border-neutral-300 dark:border-neutral-700"
                    }`}
                  onClick={(e) => setTripDetails(prevDetails => ({ ...prevDetails, tripType: "roundTrip" }))}
                >
                  Round-trip
                </div>
                <div
                  className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${tripDetails?.tripType === "oneWay"
                    ? "bg-black text-white shadow-black/10 shadow-lg"
                    : "border border-neutral-300 dark:border-neutral-700"
                    }`}
                  onClick={(e) => setTripDetails(prevDetails => ({ ...prevDetails, tripType: "oneWay" }))}
                >
                  One-way
                </div>
              </div>

              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Ticket Class:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderRadio("class", "Economy", "Economy")}
                  {renderRadio("class", "Business", "Business")}
                  {renderRadio("class", "First", "First")}
                </div>
              </div>
              <div className="mt-6">
                <label className="text-base font-semibold" htmlFor="">
                  Trip Purpose:
                </label>
                <div className="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-4">
                  {renderTripPurposeRadio("purpose", "Attending a Conference or Trade Show", "Attending a Conference or Trade Show")}
                  {renderTripPurposeRadio("purpose", "Client Meetings", "Client Meetings")}
                  {renderTripPurposeRadio("purpose", "Sales and Business Development", "Sales and Business Development")}
                  {renderTripPurposeRadio("purpose", "Training and Professional Development", "Training and Professional Development")}
                  {renderTripPurposeRadio("purpose", "Internal Company Meetings", "Internal Company Meetings")}
                  {renderTripPurposeRadio("purpose", "Market Research", "Market Research")}
                  {renderTripPurposeRadio("purpose", "Site Visits and Inspections", "Site Visits and Inspections")}
                  {renderTripPurposeRadio("purpose", "Networking Events", "Networking Events")}
                  {renderTripPurposeRadio("purpose", "Looking for Funding/Seeking Investment", "Looking for Funding/Seeking Investment")}
                  {renderTripPurposeRadio("purpose", "Other", "Other")}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderRadio = (
    name: string,
    id: string,
    label: TripClassTypes,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={tripDetails?.tripClass === label}
          id={id + name}
          name={name}
          onChange={() => setTripDetails(prevDetails => ({ ...prevDetails, tripClass: label }))}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderTripPurposeRadio = (
    name: string,
    id: string,
    label: TripPurposeTypes,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center ">
        <input
          defaultChecked={tripDetails?.purpose === label}
          id={id + name}
          name={name}
          onChange={() => setTripDetails(prevDetails => ({ ...prevDetails, purpose: label }))}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  // const renderInputGuests = () => {
  //   const isActive = fieldNameShow === "guests";
  //   let guestSelected = "";
  //   if (guestInput.guestAdults || guestInput.guestChildren) {
  //     const guest =
  //       (guestInput.guestAdults || 0) + (guestInput.guestChildren || 0);
  //     guestSelected += `${guest} guests`;
  //   }

  //   if (guestInput.guestInfants) {
  //     guestSelected += `, ${guestInput.guestInfants} infants`;
  //   }

  //   return (
  //     <div
  //       className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
  //         ? "rounded-2xl shadow-lg"
  //         : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
  //         }`}
  //     >
  //       {!isActive ? (
  //         <button
  //           className={`w-full flex justify-between text-sm font-medium p-4`}
  //           onClick={() => setFieldNameShow("guests")}
  //         >
  //           <span className="text-neutral-400">Who</span>
  //           <span>{guestSelected || `Add guests`}</span>
  //         </button>
  //       ) : (
  //         <GuestsInput defaultValue={guestInput} onChange={setGuestInput} />
  //       )}
  //     </div>
  //   );
  // };

  return (
    <div>
      <div className="w-full space-y-5">
        {renderInputLocationPickup()}
        {/*  */}
        {renderInputLocationDropoff()}
        {/*  */}
        {renderGenerals()}
        {/*  */}
        {renderInputDates()}
        {/*  */}
        {/* {renderInputGuests()} */}
      </div>
    </div>
  );
};

export default observer(FlightSearchForm)
