import React from 'react';
interface NoResultCardProps {
    displayName: string;
}

const NoResultCard: React.FC<NoResultCardProps> = ({ displayName }) => (
    <div className={`nc-NoResultCard relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}>
        <div className="mt-3">
            <h2 className={`text-base font-medium`}>
                <span className="line-clamp-1">{displayName}</span>
            </h2>
            <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}></span>
        </div>
        <div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
            <span className="text-xs font-medium pt-[1px]"> No Results Found</span>
        </div>
    </div>
);

export default NoResultCard;