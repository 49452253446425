import FooterNav from "components/FooterNav";
import NotificationCard from "components/NotificationCard/NotificationCard";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import { notificationStore } from "stores/NotificationsStore";
import WidgetHeading1 from "../BlogPage/WidgetHeading1";

export interface NotificationsProps {
  className?: string;
  categories?: TaxonomyType[];
}

const categoriesDemo: TaxonomyType[] = DEMO_CATEGORIES//.filter((_, i) => i < 5);

const Notifications: FC<NotificationsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  categories = categoriesDemo,
}) => {


  useEffect(() => {
    if (notificationStore?.notifications?.length === 0) {
      notificationStore.pullNotifications()
    }
  }, []);

  return (
    <div
      className={`nc-WidgetCategories m-2 rounded-3xl  overflow-hidden ${className}`}
      data-nc-id="WidgetCategories"
    >
      <WidgetHeading1
        title="Notifications"
        viewAll={{ label: "", href: "/#" }}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {Array.isArray(notificationStore?.notifications) && notificationStore?.notifications.map((notification) => (
            <NotificationCard
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={notification.id}
              notification={notification}
              size="normal"
            />
          ))}
        </div>
      </div>
      <FooterNav />
    </div>
  );
};

export default observer(Notifications)
