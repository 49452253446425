import { BuildingLibraryIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";

export interface CommunityCardProps {
    className?: string;
    community: {
        id: string;
        name: string;
        logo?: string;
        description?: string;
        city?: string;
        country: string;
        website?: string;
        userCount?: number;
        workspaceType?: string;
    };
    index?: number;
}

const CommunityCard: FC<CommunityCardProps> = ({
    className = "",
    community,
    index,
}) => {
    const { id, name, logo, description, city, country, workspaceType, userCount } = community;

    return (
        <Link
            to={`/community/${id}`}
            className={`nc-CardTripBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7 h-64 w-60 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
            data-nc-id="CardTripBox"
        >
            <Badge className="absolute left-3 top-3" color="blue" name={`${userCount}`} />
            <Avatar
                sizeClass="w-20 h-20 p-1 bg-white rounded-full" // Added padding and white background
                radius="rounded-full object-contain"
                imgUrl={logo}
                userName={name}
            />
            <div className="mt-3">
                <h2 className="text-base font-medium">
                    <span className="line-clamp-1">{name}</span>
                </h2>
                {city && (
                    <span className="block mt-1.5 text-xs text-neutral-500 dark:text-neutral-400">
                        {city}, {country}
                    </span>
                )}
                {/* {description && (
          <span className="block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400 line-clamp-2">
            {description}
          </span>
        )} */}
            </div>
            {workspaceType && (
                <div className="relative py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center">
                    <span className="text-xs font-medium truncate max-w-[120px]">
                        {workspaceType}
                    </span>
                    <UserGroupIcon className="w-5 h-5 ml-2" />
                </div>
            )}
        </Link>
    );
};

export default CommunityCard;
