import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import CommonLayout from 'containers/AccountPage/CommonLayout';
import { interests } from 'containers/OnBoarding/OnBoardingStep4';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';

function PageMyInterest({ className = "" }) {
    const { currentUser } = authStore
    const myInterests = currentUser?.profile?.interests || [];

    function classNames(...classes: string[]): string {
        return classes.filter(Boolean).join(' ');
    }

    const toggleTopic = (interest: string) => {
        if (!myInterests) {
            return;
        }

        const interestsResult = myInterests.includes(interest) ? myInterests.filter((id) => id !== interest) : myInterests.length < 5 ? [...myInterests, interest] : myInterests

        if (currentUser?.id) {
            authStore.updateProfile({ id: currentUser.id, interests: interestsResult });
        }
    };

    return (
        <CommonLayout>
            <div className="space-y-6 sm:space-y-8">

                <h2 className="text-3xl font-semibold">Set up your interests</h2>
                <p className="text-center text-gray-500 mb-6">Choose up to 5 interests</p>

                <div className="w-full max-w-md mx-auto  text-white rounded-lg">
                    {Object.keys(interests).map((interset) => (
                        <Disclosure key={interset}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-4 mt-2 text-sm font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75">
                                        <span>{`${interests[interset].emoji} ${interset}`}</span>
                                        {open ? (
                                            <ChevronUpIcon className="w-5 h-5 text-white" />
                                        ) : (
                                            <ChevronDownIcon className="w-5 h-5 text-white" />
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300">
                                        <div className="flex flex-wrap gap-2">
                                            {interests[interset].topics.map((topic) => (
                                                <button
                                                    key={topic}
                                                    className={classNames(
                                                        'px-3 py-1 border rounded-full text-sm',
                                                        myInterests.includes(topic) ? 'bg-blue-500 text-white border-blue-500' : 'bg-gray-800 text-white border-gray-600 hover:bg-gray-700'
                                                    )}
                                                    onClick={() => toggleTopic(topic)}
                                                >
                                                    {topic}
                                                </button>
                                            ))}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </CommonLayout>
    )
}

export default observer(PageMyInterest)