import FooterNav from 'components/FooterNav';
import { observer } from 'mobx-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TypingEffect } from 'shared/Typing/TypingEffect';
import { authStore } from 'stores/AuthStore';

const MainPage = () => {
    const navigate = useNavigate();
    const navigateTo = (path: string) => {
        navigate(path);
    };

    return (
        <div className="flex flex-col min-h-screen items-center justify-between">
            {/* Header Section */}
            <header className="w-full mt-10 flex items-center justify-center">
                <h1 className="text-3xl font-bold text-center">
                    <TypingEffect text="What would you like to do today?🚀" speed={50} />
                </h1>
            </header>

            {/* Main Content */}
            <main className="flex flex-1 flex-col items-center justify-center space-y-6 px-4 w-full max-w-3xl">
                {/* Buttons */}
                <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full">
                    {/* Button 1 */}
                    <button
                        onClick={() => navigateTo('/home')}
                        className="flex-1 h-32 md:h-40 border border-gray-400 text-gray-700 font-bold rounded-lg hover:shadow-lg p-4"
                    >
                        <span className="text-lg">Explore MeetWith</span>
                        <p className="text-sm text-gray-500 mt-2">
                            Discover connections, opportunities, and events for business travelers
                        </p>
                    </button>

                    {/* Button 2 */}
                    {authStore.currentUser?.profile?.enterprise ? (
                        <button
                            onClick={() => navigateTo('/ai-search-colleagues')}
                            className="flex-1 h-32 md:h-40 border border-gray-400 text-gray-700 font-bold rounded-lg hover:shadow-lg p-4"
                        >
                            <span className="text-lg">AI Matching</span>
                            <p className="text-sm text-gray-500 mt-2">
                                Leverage AI to discover and connect with colleagues from your enterprise
                            </p>
                        </button>
                    ) : (
                        <button
                            onClick={() => navigateTo('/ai-search-opportunities')}
                            className="flex-1 h-32 md:h-40 border border-gray-400 text-gray-700 font-bold rounded-lg hover:shadow-lg p-4"
                        >
                            <span className="text-lg">AI Matching</span>
                            <p className="text-sm text-gray-500 mt-2">
                                Leverage AI to find and connect with professionals who align with your goals and interests
                            </p>
                        </button>
                    )}

                    {/* Button 3 */}
                    <button
                        onClick={() => navigateTo('/professional/matches')}
                        className="flex-1 h-32 md:h-40 border border-gray-400 text-gray-700 font-bold rounded-lg hover:shadow-lg p-4"
                    >
                        <span className="text-lg">View Your Matches</span>
                        <p className="text-sm text-gray-500 mt-2">
                            Explore potential connections curated for your business trip
                        </p>
                    </button>
                </div>
            </main>

            {/* Footer Section */}
            <footer className="w-full h-[56px] bg-gray-200">
                <FooterNav />
            </footer>
        </div>
    );
};

export default observer(MainPage);
