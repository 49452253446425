import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import { ITransformedEvent, PredictHQEventResponse } from "data/types";
import useNcId from "hooks/useNcId";
import { FC, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NextPrev from "shared/NextPrev/NextPrev";
import EventCard from "./EventCard";
export interface SectionEventsSliderProps {
  className?: string;
  itemClassName?: string;
  events: ITransformedEvent[];
  heading?: string;
  subHeading?: string;
  categoryCardType?: "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
  sliderStyle?: "style1" | "style2";
  uniqueClassName: string;
  sourceId: string;
}


const SectionEventSlider: FC<SectionEventsSliderProps> = ({
  heading = "Upcoming Events in Your City",
  subHeading = "",
  events = [],
  className = "",
  itemClassName = "",
  itemPerRow = 5,
  categoryCardType = "card5",
  sliderStyle = "style1",
  uniqueClassName,
  sourceId
}) => {
  const navigate = useNavigate();

  const UNIQUE_CLASS = "SectionEventsSlider__" + uniqueClassName + useNcId();

  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: itemPerRow,
      gap: 16,
      bound: true,
      breakpoints: {
        1280: {
          perView: itemPerRow - 1,
        },
        1024: {
          gap: 16,
          perView: itemPerRow - 1,
        },
        768: {
          gap: 16,
          perView: itemPerRow - 2,
        },
        640: {
          gap: 16,
          perView: itemPerRow - 3,
        },
        500: {
          gap: 16,
          perView: 2.3,
        },
      },
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 100);
  }, [MY_GLIDEJS, UNIQUE_CLASS]);

  const renderCard = (event: ITransformedEvent, index: number) => {
    switch (categoryCardType) {
      default:
        return <EventCard event={event} />;
    }
  };

  return (
    <div className={`nc-SectionEventSlider  ${className}`}>
      <div className={`${UNIQUE_CLASS}`}>
        <Heading
          className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
          desc={subHeading}
          hasNextPrev={false}
          isCenter={sliderStyle === "style2"}
        >
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {events?.map((item, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>

        {sliderStyle === "style2" && (
          <NextPrev className="justify-center mt-16" />
        )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary onClick={() => navigate(`/events/${sourceId}`)}>Show me more </ButtonSecondary>
      </div>
    </div>
  );
};

export default SectionEventSlider