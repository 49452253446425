import { Place, TripDetails } from "interface/trip.interface";
import moment from "moment-timezone";
import { FC, useState } from "react";
import { AutocompletePrediction } from "react-places-autocomplete";
import { tripStore } from "stores/TripStore";
import { searchFormStore } from "stores/Views/SearchFormStore";
import { converSingleSelectedDateToString } from "utils/converSingleSelectedDateToString";
import DateAndTimeInput from "../DateAndTimeInput";
import PlaceAutocompleteInput from "../PlaceInput";

type AddTripPlaceFormProps = {
  trip: TripDetails; // Replace TripDetails with the actual type of your trip
  setLocation?: React.Dispatch<React.SetStateAction<google.maps.places.AutocompletePrediction | null>>
  setDate?: React.Dispatch<React.SetStateAction<Date | null>>
};

const AddTripPlaceForm: FC<AddTripPlaceFormProps> = ({
  trip,
  setLocation,
  setDate
}) => {

  const [fieldNameShow, setFieldNameShow] = useState<"locationPickup" | "locationDropoff" | "dates">("locationPickup");
  //
  const [locationInputPickUp, setLocationInputPickUp] = useState<AutocompletePrediction | null>(null);

  const [date, setLocalDate] = useState<Date | null>(new Date(trip.startDate) || null);

  // const [dropOffLocationType, setDropOffLocationType] = useState<"same" | "different">("same");

  const renderInputLocationPickup = () => {
    const isActive = fieldNameShow === "locationPickup";
    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4`}
            onClick={() => setFieldNameShow("locationPickup")}
          >
            <span className="text-neutral-400">Pick up</span>
            <span>{locationInputPickUp?.description || "Location"}</span>
          </button>
        ) : (
          <PlaceAutocompleteInput
            headingText="Add a place"
            defaultValue={locationInputPickUp?.description}
            latitude={trip?.toLat}
            longitude={trip?.toLong}
            country={trip?.toCountry}
            onChange={(value) => {
              setLocationInputPickUp(value);
              setLocation?.(value);
              // if (dropOffLocationType === "different") {
              //   setFieldNameShow("locationDropoff");
              // } else {
              //   setFieldNameShow("dates");
              // }
            }}
          />
        )}
      </div>
    );
  };

  // const renderInputLocationDropoff = () => {
  //   const isActive = fieldNameShow === "locationDropoff";
  //   return (
  //     <div
  //       className={`w-full bg-white dark:bg-neutral-800 ${isActive
  //         ? "rounded-2xl shadow-lg"
  //         : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
  //         }`}
  //     >
  //       {!isActive ? (
  //         <button
  //           className={`w-full flex justify-between text-sm font-medium p-4`}
  //           onClick={() => setFieldNameShow("locationDropoff")}
  //         >
  //           <span className="text-neutral-400">Drop off</span>
  //           <span>{locationInputPickUp || "Location"}</span>
  //         </button>
  //       ) : (
  //         <LocationInput
  //           headingText="Drop off?"
  //           defaultValue={locationInputPickUp}
  //           onChange={(value) => {
  //             setLocationInputPickUp(value);
  //             setFieldNameShow("dates");
  //           }}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  const renderInputDates = () => {
    const isActive = fieldNameShow === "dates";

    return (
      <div
        className={`w-full bg-white dark:bg-neutral-800 overflow-hidden ${isActive
          ? "rounded-2xl shadow-lg"
          : "rounded-xl shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)]"
          }`}
      >
        {!isActive ? (
          <button
            className={`w-full flex justify-between text-sm font-medium p-4  `}
            onClick={() => setFieldNameShow("dates")}
          >
            <span className="text-neutral-400">When</span>
            <span>{date ? converSingleSelectedDateToString(date) : "Add date"}</span>
          </button>
        ) : (
          <DateAndTimeInput
            selectedDate={date}
            onDateChange={(date) => {
              setLocalDate(date);
              setDate?.(date);
              // setFieldNameShow("locationPickup");  
            }}
            minDate={moment(trip?.startDate || null).toDate()}
            maxDate={moment(trip?.endDate || null).toDate()}
          />
        )}
      </div>
    );
  };

  // const renderRadioBtn = () => {
  //   return (
  //     <div className="flex justify-center items-center space-x-3">
  //       <div
  //         className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer ${dropOffLocationType === "same"
  //           ? "bg-black text-white shadow-black/10 shadow-lg"
  //           : "border border-neutral-300 dark:border-neutral-700"
  //           }`}
  //         onClick={(e) => setDropOffLocationType("same")}
  //       >
  //         Same drop off
  //       </div>
  //       <div
  //         className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer ${dropOffLocationType === "different"
  //           ? "bg-black text-white shadow-black/10 shadow-lg"
  //           : "border border-neutral-300 dark:border-neutral-700"
  //           }`}
  //         onClick={(e) => setDropOffLocationType("different")}
  //       >
  //         Different drop off
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div>
      <div className="w-full space-y-5">
        {/* {renderRadioBtn()} */}

        {renderInputLocationPickup()}
        {/*  */}
        {/* {dropOffLocationType === "different" && renderInputLocationDropoff()} */}
        {/*  */}
        {renderInputDates()}
        {/*  */}
      </div>
    </div>
  );
};

export default AddTripPlaceForm;
