import { fetchLikeMindedLocals, PROFESSIONAL_LOCALS_BASE_URL, ProfessionalProfileDto } from 'api/professionalLocals';
import { MATCHING_TRAVELERS_BASE_URL } from 'api/trip';
import FooterNav from 'components/FooterNav';
import Heading from 'components/Heading/Heading';
import { PaginationLinks, PaginationResult } from 'interface/pagination';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useParams } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import BadgeScore from 'shared/BadgeScore/BadgeScore';

export interface MatchsViewerPageProps {
  locals?: ProfessionalProfileDto[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const MatchsViewerPage: React.FC<MatchsViewerPageProps> = ({
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const { tripId } = useParams();
  const [locals, setProfessionalLocals] = useState<ProfessionalProfileDto[]>([]);
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (!tripId || tripId == '1') {
      fetchData(PROFESSIONAL_LOCALS_BASE_URL);
    } else {
      fetchData(`${MATCHING_TRAVELERS_BASE_URL}${tripId}`);
    }
  }, []);

  const fetchData = async (url: string) => {
    try {
      const response = await returnMockedData()
      const data: PaginationResult<ProfessionalProfileDto[]> = response.data;
      setProfessionalLocals((prevVcs) => [...prevVcs, ...data.items]);
      setPaginationLinks(data.links);
      setHasMore(!!data.links.next);
    } catch (error) {
      console.error('Failed to fetch VC firms:', error);
    }
  };

  const fetchMoreVcFirms = () => {
    if (paginationLinks && paginationLinks.next) {
      fetchLikeMindedLocals(paginationLinks.next);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full h-full px-4 sm:px-6 lg:px-8 py-4 lg:py-12 bg-neutral-50 dark:bg-neutral-900">
      <Heading desc="Discover professionals curated specifically for you, based on your interests and goals.">
        <div className="text-4xl font-semibold">Your Recommended Connections</div>
      </Heading>
      <InfiniteScroll
        dataLength={locals.length}
        next={fetchMoreVcFirms}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: 'center' }}><b>No data to show</b></p>}
      >
        <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
          {locals.map((local, i) => (
            <Link
              key={i}
              to={`/user/${local.id}`}
              className={`nc-CardOfProfessional relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
              data-nc-id="CardOfProfessional"
            >
              <BadgeScore
                className="absolute right-2 top-2"
                color="gray"
                name=''
                matchScore={local?.matchScore}
              />

              <div className="relative flex-shrink-0 w-12 h-12 rounded-full overflow-hidden">
                <Avatar
                  imgUrl={local?.avatar ?? ''}
                  userName={local.displayName}
                  sizeClass="h-10 w-10 text-base"
                  containerClassName="flex-shrink-0 mr-3"
                  radius="rounded-full"
                />
                {/* <NcImage src={getAvatar(local)} containerClassName="absolute inset-0" /> */}
              </div>
              <div className="ml-4 flex-grow overflow-hidden">
                <h2 className="text-base font-medium">
                  <span className="line-clamp-1">{local.displayName} {`${local.companyName ? `(${local.companyName})` : ''}`}</span>
                </h2>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>{local.industry}</span>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>{local.role}</span>
              </div>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      <FooterNav />
    </div>
  );
};

export default MatchsViewerPage;

function returnMockedData() {
  return {
    data: {
      "items": [
        {
          "id": "ec3d8e40-45d8-4bbd-aa94-c054c34e97da",
          "displayName": "Sally Doyle",
          "avatar": null,
          "desc": "No bio available.",
          "role": "Data Scientist",
          "industry": "E-learning",
          "companyName": "Quick",
          "placeDescription": "Yavne, Israel",
          "verifiedAccount": false,
          "bio": null,
          "city": "Yavne"
        },
        {
          "id": "07b59e70-55f5-4f83-91d7-4e6e34f3536d",
          "displayName": "Shimron Shitrit",
          "avatar": "https://media.licdn.com/dms/image/v2/C4D03AQEjFMFXiyE3Jg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1650355317741?e=1734566400&v=beta&t=A-MYtydaf1KNygRZ16CRwXF-tNoxXojqxuhNjgFnhYo",
          "desc": "No bio available.",
          "role": "Legal Advisor",
          "industry": "Legal Services",
          "companyName": "Goldfarb",
          "placeDescription": "Brosh St 5, Netanya, Israel",
          "verifiedAccount": true,
          "bio": null,
          "city": "Netanya"
        },
        {
          "id": "e1c60913-2901-466c-9bf3-d2ed129aee99",
          "displayName": "Loren Rohan",
          "avatar": null,
          "desc": "Hi you",
          "role": "Financial Analyst",
          "industry": "Financial Services",
          "companyName": "Viz",
          "placeDescription": "Tel Aviv-Yafo, Israel",
          "verifiedAccount": false,
          "bio": "Hi you",
          "city": "Tel Aviv-Yafo"
        },
        {
          "id": "b424eafa-8fae-4cd3-888e-a2bf36141fd8",
          "displayName": "Ayesha Fedelmid",
          "avatar": null,
          "desc": "No bio available.",
          "role": "Flight Attendant",
          "industry": "Banking",
          "companyName": "Citibank",
          "placeDescription": "Tel Aviv-Yafo, Israel",
          "verifiedAccount": false,
          "bio": null,
          "city": "Tel Aviv-Yafo"
        },
        {
          "id": "07f125df-bdee-4742-bf5e-cf9e25217515",
          "displayName": "Shimron Shitrit",
          "avatar": "https://storage.googleapis.com/meet-8a8ee.appspot.com/profile/07f125df-bdee-4742-bf5e-cf9e25217515/1722959888492-cat-1709235.jpg",
          "desc": "wf",
          "role": "Financial Analyst",
          "industry": "Design",
          "companyName": "Tets",
          "placeDescription": "Kfar Netter, Israel",
          "verifiedAccount": true,
          "bio": "wf",
          "city": "Kfar Netter"
        },
        {
          "id": "6e1d101c-d92a-4296-b7d0-8cdf19af85d1",
          "displayName": "Jordan Nicolas",
          "avatar": "https://avatars.githubusercontent.com/u/2486894",
          "bgImage": "https://loremflickr.com/640/480?lock=995363282485248",
          "desc": "Passionate product manager with experience in building teams and delivering results in fast-paced environments.",
          "role": "Future Brand Designer",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Lake Makaylaworth",
          "verifiedAccount": true,
          "bio": "Passionate product manager with experience in building teams and delivering results in fast-paced environments.",
          "city": "Tel Aviv"
        },
        {
          "id": "d66c3a99-3bd1-4d2c-b451-fd1a82dc35a2",
          "displayName": "Frances Bayer",
          "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/663.jpg",
          "bgImage": "https://picsum.photos/seed/84cu05OT/640/480",
          "desc": "Frontend developer with a knack for creating sleek and responsive user interfaces. Coffee lover.",
          "role": "Product Research Strategist",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "North Christyville",
          "verifiedAccount": true,
          "bio": "Frontend developer with a knack for creating sleek and responsive user interfaces. Coffee lover.",
          "city": "Tel Aviv"
        },
        {
          "id": "1eeb2cea-44a7-4966-b097-a6af26443335",
          "displayName": "Raquel Auer",
          "avatar": "https://avatars.githubusercontent.com/u/64242752",
          "bgImage": "https://picsum.photos/seed/ly4oBaaIFX/640/480",
          "desc": "Backend developer who loves solving complex problems with simple solutions. Big fan of open-source projects.",
          "role": "Lead Metrics Director",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Carmelstad",
          "verifiedAccount": true,
          "bio": "Backend developer who loves solving complex problems with simple solutions. Big fan of open-source projects.",
          "city": "Tel Aviv"
        },
        {
          "id": "f763a240-0c05-4b79-ad3e-54bf9cd4d0c6",
          "displayName": "Meghan Jacobson",
          "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/404.jpg",
          "bgImage": "https://loremflickr.com/640/480?lock=2976165509726208",
          "desc": "Experienced UX/UI designer with a passion for improving user experiences. Always curious about user behaviors.",
          "role": "Customer Quality Manager",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Port Cieloberg",
          "verifiedAccount": true,
          "bio": "Experienced UX/UI designer with a passion for improving user experiences. Always curious about user behaviors.",
          "city": "Tel Aviv"
        },
        {
          "id": "00a73455-f503-4c9f-8ee1-90bfa7469428",
          "displayName": "Kellie Cormier",
          "avatar": "https://avatars.githubusercontent.com/u/37232952",
          "bgImage": "https://picsum.photos/seed/Tvs4IjZnt/640/480",
          "desc": "HR specialist focused on building a positive culture in fast-growing startups. Believes in people-first approaches.",
          "role": "Forward Communications Agent",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "South Rico",
          "verifiedAccount": true,
          "bio": "HR specialist focused on building a positive culture in fast-growing startups. Believes in people-first approaches.",
          "city": "Tel Aviv"
        },
        {
          "id": "dcec72f5-b3d5-4f9a-94b9-3dcc39a657b0",
          "displayName": "Bobbie Hane",
          "avatar": "https://avatars.githubusercontent.com/u/17119676",
          "bgImage": "https://loremflickr.com/640/480?lock=5738378351869952",
          "desc": "Data analyst skilled in transforming complex data into actionable insights. Loves numbers and storytelling.",
          "role": "National Directives Representative",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Atascocita",
          "verifiedAccount": true,
          "bio": "Data analyst skilled in transforming complex data into actionable insights. Loves numbers and storytelling.",
          "city": "Tel Aviv"
        },
        {
          "id": "a0e6efc7-1531-4401-9a94-685304ec234e",
          "displayName": "Jake Hoppe-Ryan",
          "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1017.jpg",
          "bgImage": "https://loremflickr.com/640/480?lock=3540395113840640",
          "desc": "Marketing specialist with experience in digital campaigns, brand awareness, and content creation.",
          "role": "National Response Facilitator",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Fort Adonisboro",
          "verifiedAccount": true,
          "bio": "Marketing specialist with experience in digital campaigns, brand awareness, and content creation.",
          "city": "Tel Aviv"
        },
        {
          "id": "02c3710e-ba89-4302-a72b-ef42dd3c2fb9",
          "displayName": "Dr. Warren Kohler",
          "avatar": "https://avatars.githubusercontent.com/u/13705711",
          "bgImage": "https://picsum.photos/seed/lTIF3WE/640/480",
          "desc": "DevOps engineer dedicated to building infrastructure that scales and optimizing deployment processes.",
          "role": "Lead Infrastructure Officer",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "New Richmondton",
          "verifiedAccount": true,
          "bio": "DevOps engineer dedicated to building infrastructure that scales and optimizing deployment processes.",
          "city": "Tel Aviv"
        },
        {
          "id": "ee750ca8-612d-48d7-9552-50d6abab91aa",
          "displayName": "Virgil Bruen",
          "avatar": "https://avatars.githubusercontent.com/u/11916845",
          "bgImage": "https://picsum.photos/seed/FLYvzVq/640/480",
          "desc": "Software engineer passionate about creating efficient and scalable backend services. Enjoys learning new technologies.",
          "role": "Corporate Group Producer",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Pfannerstillville",
          "verifiedAccount": true,
          "bio": "Software engineer passionate about creating efficient and scalable backend services. Enjoys learning new technologies.",
          "city": "Tel Aviv"
        },
        {
          "id": "903cb332-8e1d-412d-9cd7-19fb47e525e2",
          "displayName": "Gene Toy",
          "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/457.jpg",
          "bgImage": "https://loremflickr.com/640/480?lock=3553853586800640",
          "desc": "Customer success manager with experience in working closely with clients to maximize product value.",
          "role": "Future Security Director",
          "industry": "FinTech",
          "companyName": "Mock Company",
          "placeDescription": "Clarissaview",
          "verifiedAccount": true,
          "bio": "Customer success manager with experience in working closely with clients to maximize product value.",
          "city": "Tel Aviv"
        }
      ],
      "meta": {
        "totalItems": 15,
        "itemCount": 15,
        "itemsPerPage": 50,
        "totalPages": 1,
        "currentPage": 1
      },
      "links": {
        "first": "http://localhost:5000/venture-capital-firm?limit=50",
        "previous": "",
        "next": "",
        "last": "http://localhost:5000/venture-capital-firm?page=1&limit=50"
      }
    }
  }
}