import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchVcById, VentureCapitalFirmFilterDto } from 'api/vc-firms';
import FooterNav from 'components/FooterNav';

const PageVcFirm = () => {
    const { id } = useParams();
    
    const [vc, setVc] = useState<VentureCapitalFirmFilterDto>();

    useEffect(() => {
        const fetchVcFirm = async () => {
            try {                
                if (!id) return
                const { data } = await fetchVcById(id);
                setVc(data);
            } catch (error) {
                console.error('Failed to fetch VC firm:', error);
            }
        };

        fetchVcFirm();
    }, [id]);

    if (!vc) return <div>Loading...</div>;

    return (
        <div className="max-w-3xl mx-auto  shadow-lg rounded-lg overflow-hidden mt-4 mb-8 p-6">
            <div className="flex items-center mb-4">
                <img src={`https://logo.clearbit.com/${vc?.website?.replace('https://', '').replace('http://', '')}`} alt={`${vc.vcFirm} Logo`} className="w-16 h-16 mr-4" />
                <div className="font-bold text-2xl">{vc.vcFirm}</div>
            </div>
            <p className="text-gray-700 text-base mb-4">
                <a href={vc.website} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">{vc.website}</a>
            </p>
            <div className="mb-4">
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">{vc.countries}</span>
                <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700">{vc.cities}</span>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Industries:</label>
                <div className="flex flex-wrap">
                    {vc?.industries?.split(', ').map((industry, index) => (
                        <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{industry}</span>
                    ))}
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Stages:</label>
                <div className="flex flex-wrap">
                    {vc?.stages?.split(', ').map((stage, index) => (
                        <span key={index} className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{stage}</span>
                    ))}
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Founded:</label>
                <p className="text-gray-700 text-base">{vc.foundedYear}</p>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Founders:</label>
                <p className="text-gray-700 text-base">{vc.founders}</p>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Number of Investments:</label>
                <p className="text-gray-700 text-base">{vc.numberOfInvestments}</p>
            </div>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">Contact:</label>
                <p className="text-gray-700 text-base">
                    <a href={`mailto:${vc.contactInformation}`} className="text-blue-500 hover:underline">{vc.contactInformation}</a>
                </p>
            </div>
            <FooterNav/>
        </div>
    );
};

export default PageVcFirm;
