import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchVcFirmsByUrl, VC_FIRMS_BASE_URL, VentureCapitalFirmFilterDto } from 'api/vc-firms'
import { useNavigate } from 'react-router-dom';
import { PaginationLinks, PaginationResult } from 'interface/pagination';
import FooterNav from 'components/FooterNav';

export default function PageVcFirms() {
    const [vcs, setVcFirms] = useState<VentureCapitalFirmFilterDto[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
    const [hasMore, setHasMore] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchVcFirms(VC_FIRMS_BASE_URL);
    }, []);

    const fetchVcFirms = async (url: string) => {
        try {
            const response = await fetchVcFirmsByUrl(url);
            const data: PaginationResult<VentureCapitalFirmFilterDto[]> = response.data;
            setVcFirms((prevVcs) => [...prevVcs, ...data.items]);
            setPaginationLinks(data.links);
            setHasMore(!!data.links.next);
        } catch (error) {
            console.error('Failed to fetch VC firms:', error);
        }
    };

    const fetchMoreVcFirms = () => {
        if (paginationLinks && paginationLinks.next) {
            fetchVcFirms(paginationLinks.next);
        }
    };

    const handleCardClick = (id: number) => {
        navigate(`/vc-firm/${id}`);
    };

    return (
        <div className="flex flex-col items-center justify-center w-full h-full">
            <InfiniteScroll
                dataLength={vcs.length}
                next={fetchMoreVcFirms}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: 'center' }}><b>No more firms to show</b></p>}
            >
                {vcs.map((vc) => (
                    <div
                        key={vc.id}
                        className="w-full max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden my-4 cursor-pointer transition-transform transform hover:scale-105"
                        onClick={() => vc.id && handleCardClick(vc.id)}
                    >
                        <div className="px-6 py-4">
                            <div className="flex items-center mb-4">
                                <img src={`https://logo.clearbit.com/${vc?.website?.replace('https://', '').replace('http://', '')}`} alt={`${vc.vcFirm} Logo`} className="w-12 h-12 mr-4" />
                                <div className="font-bold text-xl">{vc.vcFirm}</div>
                            </div>
                            <p className="text-gray-700 text-base mb-4">
                                <a href={vc.website} className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">{vc.website}</a>
                            </p>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Industries:</label>
                                <div className="flex flex-wrap">
                                    {vc?.countries?.split(', ').slice(0, 3).map((country, index) => (
                                        <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{country}</span>
                                    ))}
                                    {vc.countries && vc.countries.split(', ').length > 3 && <span className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">...</span>}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Industries:</label>
                                <div className="flex flex-wrap">
                                    {vc?.industries?.split(', ').slice(0, 3).map((industry, index) => (
                                        <span key={index} className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{industry}</span>
                                    ))}
                                    {vc.industries && vc.industries.split(', ').length > 3 && <span className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">...</span>}
                                </div>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Founders:</label>
                                <p className="text-gray-700 text-base">{vc?.founders?.split(', ').slice(0, 2).join(', ')}{vc.founders && vc.founders.split(', ').length > 2 && ', ...'}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
            <FooterNav/>
        </div>
    );
}

