import axiosInstance from 'utils/setAuthorizationHeader';
// Interface for the axios response
export interface ConnectionResponse {
    id: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    sender: {
        id: string;
    };
    receiver: {
        id: string;
    };
}
// Function to get a connection
export async function getConnection(userId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/connection/${userId}`, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to get connection');
    }
}

// Function to approve a connection
export async function approveConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/approve`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to approve connection');
    }
}

// Function to cancel a connection
export async function cancelConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/cancel`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to cancel connection');
    }
}

// Function to decline a connection
export async function declineConnection(connectionId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}/connection/${connectionId}/decline`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to decline connection');
    }
}

// Function to request a connection
export async function requestConnection(receiverId: string): Promise<ConnectionResponse> {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/connection/request`, { receiverId }, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw new Error('Failed to request connection');
    }
}