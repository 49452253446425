import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderOneMonth from "components/DatePickerCustomHeaderOneMonth";
import React, { FC, useState, useEffect } from "react";
import DatePicker from "react-datepicker";

export interface DateAndTimeInputProps {
  className?: string;
  selectedDate?: Date | null;
  onDateChange?: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}

const DateAndTimeInput: FC<DateAndTimeInputProps> = ({
  className = "",
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(selectedDate || new Date());

  useEffect(() => {
    if (selectedDate) {
      setStartDate(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  };

  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}>
        <DatePicker
          selected={startDate}
          onChange={handleDateChange}
          showTimeInput
          showPopperArrow={false}
          inline
          minDate={minDate}
          maxDate={maxDate}
          renderCustomHeader={(p) => <DatePickerCustomHeaderOneMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default DateAndTimeInput;