import FooterNav from 'components/FooterNav';
import CommonLayout from 'containers/AccountPage/CommonLayout';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import { goals } from 'stores/OnboardingStore';

function PageMyGoals({ className = "" }) {
    const { currentUser } = authStore

    const toggleGoal = (goalName: string) => {
        const goals = currentUser?.profile?.goals?.includes(goalName)
            ? currentUser?.profile?.goals?.filter((id) => id !== goalName)
            : (currentUser?.profile?.goals?.length ?? 0) < 3
                ? [...(currentUser?.profile?.goals ?? []), goalName]
                : currentUser?.profile?.goals ?? [];
        if (currentUser?.id) {
            authStore.updateProfile({ id: currentUser.id, goals });
        }
    };

    return (
        <CommonLayout>
            <div className="space-y-6 sm:space-y-8">
                <h2 className="text-3xl font-semibold">Set up your goals</h2>
                <p className="text-center text-gray-500 mb-6">Choose your top 3 goals</p>

                {/* FORM */}
                <div className="space-y-8">
                    {/* ITEM */}
                    <div
                        className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
                        data-nc-id="WidgetTags"
                    >
                        <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
                            <div className="grid grid-cols-2 gap-4">
                                {goals.map((goal) => (
                                    <button
                                        key={goal.name}
                                        className={`flex flex-col items-center justify-center w-30 h-35 p-4 border rounded-xl transition-colors duration-200 ${currentUser?.profile?.goals.includes(goal.name) ? 'dark:bg-neutral-800 border-blue-700' : 'border-gray-300'}`}
                                        onClick={() => toggleGoal(goal.name)}
                                    >
                                        <span className="text-2xl mb-2">{goal.icon}</span>
                                        <span className="text-center text-sm">{goal.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <FooterNav />
            </div>
        </CommonLayout>
    )
}

export default observer(PageMyGoals)