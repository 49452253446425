import { Dialog, Transition } from "@headlessui/react";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import GallerySlider from "components/GallerySlider/GallerySlider";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { ITransformedEvent } from "data/types";
import { FC, Fragment, useState } from "react";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { convertToLocal, formatDateRange, formatDuration } from "utils/dates";
import EventInfoIcon from "./EventInfoIcon";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";

export interface EventCardProps {
  className?: string;
  data?: ITransformedEvent;
  size?: "default" | "small";
}


const EventCard: FC<EventCardProps> = ({
  size = "default",
  className = "",
  data
}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);
  //
  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  if (!data) return null;

  const {
    timezone,
    duration,
    imageURL,
    description,
    category,
    labels,
    isPrivate,
    end,
    start,
    attendance,
    address,
    title,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3 "
          galleryImgs={[]}
          href={`event/${id}`}
        />

        <EventInfoIcon handleClick={openModalMoreFilterMobile} className="absolute right-3 top-3 z-[1]" />
        {/* {true && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {formatDateRange(start, end)}
          </span>
          <div className="flex items-center space-x-2">
            <Badge name={isPrivate ? 'Private' : 'Public'} color="green" />
            <h2 className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"}`}>
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>
          {labels.length ? (<div className="text-sm text-neutral-500 dark:text-neutral-400 font-normal mt-4">{labels.join(" · ")}</div>) : null}
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {attendance}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /Attendance
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-2 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>
                  <div  className="container px-6 py-6 flex-1 overflow-y-auto">
                  <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                    <h2 className="text-xl lg:text-2xl font-semibold">{title}</h2>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                    {/* ------------------------ */}
                    <div className="space-y-6">
                      <div className="flex flex-col sm:flex-row sm:items-center">
                        <div className="flex-shrink-0 w-full sm:w-40">
                          <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            <NcImage src={imageURL || 'default_image_url'} />
                          </div>
                        </div>
                        <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
                          <div>
                            <span className="text-base sm:text-lg font-medium mt-1 block">{address}</span>
                          </div>
                          <span className="block  text-sm text-neutral-500 dark:text-neutral-400">{labels.join(' · ')}</span>
                          <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                          <StartRating
                            reviewCount={100}
                            point={0}
                          />
                        </div>
                      </div>
                      <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                        <div className="flex-1 p-5 flex space-x-4">
                          <svg
                            className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                              stroke="#D1D5DB"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <div className="flex flex-col">
                            <span className="text-sm text-neutral-400">Date</span>
                            <span className="mt-1.5 text-lg font-semibold">{formatDateRange(start || '', end || '', timezone || '')}</span>
                          </div>
                        </div>
                        <div className="flex-1 p-5 flex space-x-4">
                          <svg
                            className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                              stroke="#D1D5DB"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>

                          <div className="flex flex-col">
                            <span className="text-sm text-neutral-400">Attendance</span>
                            <span className="mt-1.5 text-lg font-semibold">{attendance}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ------------------------ */}
                    <div className="space-y-6 pb-10">
                      <h3 className="text-2xl font-semibold">Event detail</h3>
                      <div className="flex flex-col space-y-4">
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Date</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {convertToLocal(start, timezone)}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Event state</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {'Active'}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Private event</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {isPrivate ? 'Yes' : 'No'}
                          </span>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Duration</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {formatDuration(duration || 0)}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div>
          <ButtonPrimary  href="/">Explore more stays</ButtonPrimary>
        </div> */}
                  </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilterMobile}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      {renderSliderGallery()}
      {/* <Link to={`/event/${id}`}>{renderContent()}</Link> */}
      {renderContent()}
      {renderTabMoreFilterMobile()}
      {renderTabMoreFilter()}
    </div>
  );
};

export default EventCard;
