import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

const MoonLoaderComponent: React.FC = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <MoonLoader color="blue" size={80} />
        </div>
    );
};

export default MoonLoaderComponent;