// src/stores/TripStore.js

import { deletePlaceById, deleteTripById, fetchLocalsAtDestination, fetchMatchingTravelers, fetchMyTrips, fetchNextMonthArrivals, getTripDetailsById } from "api/trip";
import { TripDetails, TripDetailsResponse } from "interface/trip.interface";
import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";

class TripStore {
    myTrips: TripDetails[] = []; // New state for storing your trips
    matchingTravelers = [];
    localsAtDestination = []; // To store locals data

    // State to store next month paginated trips
    paginatedArrivalsNextMonth: TripDetailsResponse[] = []; // State to store paginated trips
    paginationArrivalsNextMonthMeta = {}; // State to store pagination metadata

    loadingLocals = false;

    constructor() {
        makeAutoObservable(this);
    }

    async getTripById(tripId: string): Promise<TripDetails | undefined> {
        let trip = this.myTrips.find(trip => trip.id === tripId);

        if (!trip) {
            // Fetch the trip from the database or an API
            const { data } = await getTripDetailsById(tripId);
            trip = data;
        }

        return trip;
    }

    // New function to fetch paginated trips to a country
    async fetchNextMonthArrivals(country: string, page = 1, limit = 10) {
        try {
            const response = await fetchNextMonthArrivals(country, page, limit);
            
            runInAction(() => {                
                this.paginatedArrivalsNextMonth = response.items;
                this.paginationArrivalsNextMonthMeta = response.meta;
            });
        } catch (error) {
            console.error("Failed to fetch paginated trips:", error);
        }
    }

    // New function to fetch your trips
    fetchMyTrips() {
        fetchMyTrips().then(({ data }) => {
            this.setMyTrips(data.items);
        });
    }

    async fetchMatchingTravelers(tripId: string) {
        try {
            const response = await fetchMatchingTravelers(tripId);
            
            runInAction(() => {
                this.matchingTravelers = response.data?.items;
            });
        } catch (error) {
            console.error("Failed to fetch matching travelers:", error);
        }
    }

    async fetchLocalsAtDestination(tripId: string) {

        fetchLocalsAtDestination(tripId)
            .then((locals) => {
                runInAction(() => {
                    this.localsAtDestination = locals.data;
                    this.loadingLocals = false;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadingLocals = false;
                });
                console.error("Failed to fetch locals:", error);
            });
    }

    // Existing action to set the next month arrivals state
    // setNextMonthArrivals(nextMonthArrivals: TripDetailsResponse[]) {
    //     this.nextMonthArrivals = nextMonthArrivals;
    // }

    // New action to set your trips state
    setMyTrips(myTrips: TripDetails[]) {
        this.myTrips = myTrips;
    }

    setMatchingTravelers(matchingTravelers: any) {
        this.matchingTravelers = matchingTravelers;
    }

    async deleteTripById(tripId: string) {
        try {
            // Call the deleteTripById API or function to delete the trip
            await deleteTripById(tripId);
            runInAction(() => {
                // Remove the deleted trip from the store
                this.myTrips = this.myTrips.filter(trip => trip.id !== tripId);
                // Show toast message after deleting the trip
                toast.info("Trip deleted successfully!");
            });
        } catch (error) {
            console.error("Failed to delete trip:", error);
            toast.error("Failed to delete trip: " + tripId);
        }
    }

    async deletePlaceById(placeId: string) {
        try {
            // Call the deletePlaceById API or function to delete the place
            await deletePlaceById(placeId);
            runInAction(() => {
                // Remove the deleted place from the store
                this.myTrips = this.myTrips.map(trip => {
                    if (trip.places.some(place => place.id === placeId)) {
                        trip.places = trip.places.filter(place => place.id !== placeId);
                    }
                    return trip;
                });
            });
        } catch (error) {
            console.error("Failed to delete place:", error);
        }
    }
}

export const tripStore = new TripStore();
