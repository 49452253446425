import { BusinessTripState } from "components/HeroSearchFormSmall/(flight-search-form)/FlightSearchForm";

  // Function to validate trip details
 export const validateTripDetails = (details: BusinessTripState) => {
    const errors = [];

    if (!details.flyingFrom) {
      errors.push("Flying from location is required.");
    }

    if (!details.flyingTo) {
      errors.push("Flying to location is required.");
    }

    // Add more validation checks as needed...

    return errors;
  };