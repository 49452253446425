import React, { FC, useEffect, useState } from "react";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { ReactNode } from "react";
import { observer } from "mobx-react";
import { searchFormStore } from "stores/Views/SearchFormStore";
import { TripDetails } from "interface/trip.interface";
import { authStore } from "stores/AuthStore";

export interface HeaderFilterProps {
  tabActive: string;
  tabs: TripDetails[];
  heading?: ReactNode;
  subHeading?: ReactNode;
  onClickTab: (currentTab: string, trip: TripDetails) => void;
}

const HeaderFilter: FC<HeaderFilterProps> = ({
  tabActive,
  tabs,
  subHeading = "",
  heading = "",
  onClickTab,
}) => {
  const [tabActiveState, setTabActiveState] = useState(tabActive);

  useEffect(() => {
    setTabActiveState(tabActive);
  }, [tabActive]);

  const handleClickTab = (currentTab: string, trip: TripDetails) => {
    console.log("currentTab", currentTab, trip);

    onClickTab && onClickTab(currentTab, trip);
    setTabActiveState(currentTab);
  };

  const openModalLargeScreen = () => searchFormStore.setShowHeroSearch('guests')
  const openModalSmallScreen = () => searchFormStore.setShowMobileModal(true)

  return (
    <div className="flex flex-col mb-8 relative">
      <Heading desc={subHeading}>{heading}</Heading>
      <div className="flex items-center justify-between">
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          <NavItem
            key={1}
            isActive={tabActiveState === 'Explore'}
            onClick={() => handleClickTab('Explore', { toCountry: authStore.currentUser?.profile?.country } as TripDetails)}
          >
            <span className="flex items-center">
              <span className="mr-2">
                Explore
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#FFF" /* Blue for active, gray for inactive */
              >
                <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q146 0 255.5 91.5T872-559h-82q-19-73-68.5-130.5T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h80v120h-40L168-552q-3 18-5.5 36t-2.5 36q0 131 92 225t228 95v80Zm364-20L716-228q-21 12-45 20t-51 8q-75 0-127.5-52.5T440-380q0-75 52.5-127.5T620-560q75 0 127.5 52.5T800-380q0 27-8 51t-20 45l128 128-56 56ZM620-280q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Z" />
              </svg>
            </span>


          </NavItem>
          {tabs.map((item, index) => (
            <NavItem
              key={index}
              isActive={tabActiveState === item.toCountry}
              onClick={() => handleClickTab(item.toCountry, item)}
            >
              {item.toCountry}
            </NavItem>
          ))}
        </Nav>
        <span className="flex-shrink-0 hidden lg:block">
          <ButtonSecondary className="!leading-none" onClick={openModalLargeScreen}>
            <span className="">Add trip</span>
            <i className="las la-plus text-xl"></i>
          </ButtonSecondary>
        </span>
        <span className="flex-shrink-0 lg:hidden">
          <ButtonSecondary className="!leading-none" onClick={openModalSmallScreen}>
            <i className="las la-plus text-xl"></i>
          </ButtonSecondary>
        </span>
      </div>
    </div>
  );
};

export default observer(HeaderFilter);
