import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import Empty from "images/Empty.png";
import { Link } from "react-router-dom";

const EmptyResponsePage: React.FC = () => (
  <div className="nc-EmptyResponsePage">
    <Helmet>
      <title>404 || Booking React Template</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-2xl mx-auto space-y-2">
        <NcImage src={Empty} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          NO RESULTS FOUND. GO TO THE HOME PAGE.{" "}
        </span>
        <div className="pt-8">
          <Link to="/" className="block">
          <ButtonPrimary >Return Home Page</ButtonPrimary>
          </Link>
        </div>
      </header>
    </div>
  </div>
);

export default EmptyResponsePage;
