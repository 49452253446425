import React from 'react';

const Message = ({ text, date, me, recipientAvatar }: { text: string, date: string, me: boolean, recipientAvatar: string }) => {
    return (
        <div className={`message mb-4 flex ${me ? 'text-right' : ''}`}>
            {!me && (
                <div className="flex-2">
                    <div className="w-12 h-12 relative">
                        <img className="w-12 h-12 rounded-full mx-auto" src={recipientAvatar} alt="chat-user" referrerPolicy='no-referrer' />
                        <span className="absolute w-4 h-4 bg-gray-400 rounded-full right-0 bottom-0 border-2 border-white"></span>
                    </div>
                </div>
            )}
            <div className="flex-1 px-2">
                <div className={`inline-block ${me ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-full p-2 px-6`}>
                    <span>{text}</span>
                </div>
                <div className={`pl-4 ${me ? 'pr-4' : ''}`}><small className="text-gray-500">{date}</small></div>
            </div>
        </div>
    );
};

export default Message;
