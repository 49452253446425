import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import ItinerariesHeroPage from "./ItinerariesHeroPage";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { tripStore } from "stores/TripStore";
import FooterNav from "components/FooterNav";

export interface ItinerariesPageProps {
  className?: string;
}

const ItinerariesPage: FC<ItinerariesPageProps> = ({
  className = "",
}) => {

  useEffect(() => {
    tripStore.fetchMyTrips();
  }, []);

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Meet-With</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative mt-8">
        {/* SECTION HERO */}
        {/* <ItinerariesHeroPage
          currentPage="Flights"
          currentTab="Flights"
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">{tripStore.myTrips.length} flights</span>
            </>
          }
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        /> */}

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-28" />

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore top destination ✈"
          subHeading="Explore thousands of destinations around the world"
          categoryCardType="card4"
          itemPerRow={4}
          categories={DEMO_CATS}
          uniqueClassName="ListingFlightsPage-section1"
        /> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}
        <FooterNav />
      </div>
    </div>
  );
};

export default ItinerariesPage;
