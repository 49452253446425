import  { AxiosResponse } from 'axios';
import { CommunityData } from 'interface/communities';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export const COMMUNITIES_BASE_URL = `${process.env.REACT_APP_API_URL}/community`

// export async function fetchLocalCommunities(country: string): Promise<AxiosResponse<PaginationResult<any[]>>> {
export async function fetchCommunityData(community_id: string): Promise<AxiosResponse<CommunityData>> {
    return axiosInstance.get(`${COMMUNITIES_BASE_URL}/${community_id}`, { withCredentials: true })
}

export async function fetchLocalCommunities(country: string): Promise<AxiosResponse<CommunityData[]>> {
    return axiosInstance.get(`${COMMUNITIES_BASE_URL}/country/${country}`, { withCredentials: true })
}

export async function attachUserToCommunity(communityId: string, joinedBy: string): Promise<AxiosResponse<CommunityData>> {
    return axiosInstance.post(
        `${COMMUNITIES_BASE_URL}/${communityId}/attach-user`,
        {joinedBy},
        { withCredentials: true }  // Assumes authentication is handled via cookies or tokens
    );
}