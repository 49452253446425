import { INotification, NotificationType } from "api/notifications";
import { FC } from "react";
import { Link } from "react-router-dom";
import { convertUTCToLocal } from "utils/dates";

export interface NotificationCardProps {
  className?: string;
  notification: INotification;
  size?: "large" | "normal";
}

const NotificationCard: FC<NotificationCardProps> = ({
  className = "",
  size = "normal",
  notification,
}) => {
  console.log(notification);

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const { type, data, createdAt } = notification;

  const getHrefFromNotificationType = (type: string, data: any) => {    
    switch (type) {
      case NotificationType.CONNECTION_REQUEST:
        return `/user/${data.senderId}`;
      case NotificationType.MATCH_FOUND:
        return `/user/${data.matchedUserId}`;
      default:
        return '/';
    }
  }
  return (
    <div className={`nc-CardCategory1 flex items-center justify-between mx-2`}
    >
      <Link
        className={`nc-CardCategory1 flex items-center  ${className}`}
        to={getHrefFromNotificationType(type, data)}
        data-nc-id="CardCategory1"
      >
        {/* <NcImage
        containerClassName={`flex-shrink-0 ${size === "large" ? "w-20 h-20" : "w-12 h-12"
          } rounded-lg mr-4 overflow-hidden`}
        src={thumbnail}
      /> */}
        <div
          className={`nc-NcPlayIcon  bg-opacity-30 backdrop-filter backdrop-blur rounded-full w-20 h-20 p-3 lg:w-52 lg:h-52 lg:p-12 ${className}`}
          data-nc-id="NcPlayIcon"
        >
          <div className="w-full h-full bg-white rounded-full text-primary-500 relative">
            <span className="absolute inset-0 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
              </svg>
            </span>
          </div>
        </div>
        <div>
          <h2
            className={`${size === "large" ? "text-lg" : "text-base"} nc-card-title text-neutral-900 dark:text-neutral-100 font-semibold`}
          >
            {(data as { fullName: string })?.fullName}
          </h2>
          <span className={`${size === "large" ? "text-sm" : "text-xs"} block mt-[2px] text-neutral-500 dark:text-neutral-400`}>
            {(data as { message: string })?.message}
          </span>
        </div>
      </Link>
      <span className="text-xs">{convertUTCToLocal(createdAt, 'll')}</span>
      {renderXClear()}
    </div>
  );
};

export default NotificationCard;
