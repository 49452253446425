import { Tab } from "@headlessui/react";
import { ConnectionResponse, approveConnection, cancelConnection, declineConnection, getConnection, requestConnection } from "api/connections";
import { UserProfileDTO } from "api/dto/get-user-data-by-id.dto";
import { getMemberDataById } from "api/user";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import FooterNav from "components/FooterNav";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import { PostDataType } from "data/types";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import ConnectionButtons from "./ConnectionSection";
import UserTripCard from "./UserTripCard";
import BadgeScore from "shared/BadgeScore/BadgeScore";
import { authStore } from "stores/AuthStore";

export interface UserPageProps {
  className?: string;
}


const UserPage: FC<UserPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Stays", "Experiences", "Car for rent"]);
  const { userId } = useParams<{ userId: string }>(); // Extract userId from URL
  const [userData, setUserData] = useState<UserProfileDTO>(); // State to hold user data
  const [connection, setConnection] = useState<ConnectionResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const miles = 15000; // Example miles
  const tripsCount = 5; // Example trip count
  const matchesCount = 20; // Example match count
  // Fetch user data from backend
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log("Fetching user data for user ID:", userId);

        const response = await getMemberDataById(userId);
        console.log("Response:", response);

        setUserData(response.data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchUserConnection = async () => {
      try {
        const data = await getConnection(userId ?? '');
        setConnection(data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserConnection();
  }, []);

  async function handleHandshake() {
    setIsLoading(true);
    // Implement handshake logic
    console.log('Handshake request sent');
    const response = await requestConnection(userData?.id ?? '');
    setConnection(response);
    setConnection(response);
    setIsLoading(false);
  }

  async function handleCancel() {
    setIsLoading(true);
    console.log('Handshake request canceled');
    await cancelConnection(connection?.id ?? '');
    setConnection(null);
    setIsLoading(false);
  }

  async function handleConfirm() {
    setIsLoading(true);
    console.log('Handshake request confirmed');
    const response = await approveConnection(connection?.id ?? '');
    setConnection({ ...connection, ...response });
    setIsLoading(false);
  }

  async function handleDecline() {
    setIsLoading(true);
    console.log('Handshake request declined');
    const response = await declineConnection(connection?.id ?? '');
    setConnection(response);
    setIsLoading(false);
  }

  const renderSidebar = () => {
    function getSpeakingLang(languages: string[] | undefined): import("react").ReactNode {
      if (!languages) {
        return null
      }
      return (
        <div className="flex items-center space-x-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-neutral-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
            />
          </svg>
          <span className="text-neutral-6000 dark:text-neutral-300">
            Speaking {userData?.profile?.languages?.join(", ")}
          </span>
        </div>
      )
    }

    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked={userData?.profile?.verifiedAccount}
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
          imgUrl={userData?.profile?.avatar}
          userName={userData?.profile?.fullName}
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{userData?.profile?.fullName}</h2>
          {/* <StartRating className="!text-base" /> */}
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
          {userData?.profile?.bio}
        </p>

        {/* ---- */}

        <div className="flex items-center justify-center space-x-4">
          {/* <ButtonSecondary onClick={() => navigate('/messages')}><i className="las la-comment-dots text-2xl"></i></ButtonSecondary>
          <ButtonPrimary onClick={() => sendConnectionRequest(userData?.id || '')}><i className="las la-handshake text-2xl"></i></ButtonPrimary> */}
          <ConnectionButtons
            connection={connection}
            user={authStore.currentUser}
            onHandshake={handleHandshake}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onDecline={handleDecline}
            isLoading={isLoading}
          />
        </div>

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        {/* <div className="space-y-3 text-center flex flex-col items-center">
          <h4 className="text-2xl font-semibold">Achievements</h4>
          <div className=" text-center flex flex-row items-center">
            <div className="hover:bg-gray-100 cursor-pointer p-4 rounded-lg transition duration-300">
              <svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" fill="#9ca3af"><path d="M285-80v-83l124-86v-172L80-288v-102l329-231v-188q0-29 21-50t50-21q29 0 50 21t21 50v188l329 231v102L551-421v172l123 86v83l-194-59-195 59Z" /></svg>
              <span className="text-xs">Flights</span>
            </div>
            <div className="hover:bg-gray-100 cursor-pointer p-4 rounded-lg transition duration-300 flex flex-col items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="40px"
                width="40px"
                viewBox="0 -960 960 960"
                fill="#9ca3af"
              >
                <path d="M480-629 354-755l126-126 126 126-126 126ZM40-160v-160q0-29 20.5-49.5T110-390h141q17 0 32.5 8.5T310-358q29 42 74 65t96 23q51 0 96-23t75-65q11-15 26-23.5t32-8.5h141q29 0 49.5 20.5T920-320v160H660v-119q-36 33-82.5 51T480-210q-51 0-97-18t-83-51v119H40Zm120-300q-45 0-77.5-32.5T50-570q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T160-460Zm640 0q-45 0-77.5-32.5T690-570q0-46 32.5-78t77.5-32q46 0 78 32t32 78q0 45-32 77.5T800-460Z" />
              </svg>
              <span className="text-xs">Handshakes</span>
            </div>
          </div>
        </div> */}


        {/* <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div> */}

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData?.profile?.city}, {userData?.profile?.country}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9ca3af"><path d="M756-120 537-339l84-84 219 219-84 84Zm-552 0-84-84 276-276-68-68-28 28-51-51v82l-28 28-121-121 28-28h82l-50-50 142-142q20-20 43-29t47-9q24 0 47 9t43 29l-92 92 50 50-28 28 68 68 90-90q-4-11-6.5-23t-2.5-24q0-59 40.5-99.5T701-841q15 0 28.5 3t27.5 9l-99 99 72 72 99-99q7 14 9.5 27.5T841-701q0 59-40.5 99.5T701-561q-12 0-24-2t-23-7L204-120Z" /></svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData?.profile?.industry}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9ca3af"><path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm0-80h640v-440H160v440Zm240-520h160v-80H400v80ZM160-200v-440 440Z" /></svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData?.profile?.companyName}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9ca3af"><path d="M40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm440-160q-38 0-72-17.5T351-386q-17-25-42.5-39.5T253-440q22-37 93-58.5T480-520q63 0 134 21.5t93 58.5q-29 0-55 14.5T609-386q-22 32-56 49t-73 17ZM160-440q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440ZM480-560q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-680q0 50-34.5 85T480-560Z" /></svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData?.profile?.role}
            </span>
          </div>

          {getSpeakingLang(userData?.profile?.languages)}
        </div>
        {/* ---- */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* {userStats({ miles, tripsCount, matchesCount })} */}
      </div >
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{userData?.profile?.fullName}'s listings</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">Previous business trip and stays.</span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                        ? "bg-secondary-900 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_STAY_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  const userStats = ({ miles, tripsCount, matchesCount }: { miles: number, tripsCount: number, matchesCount: number }) => {
    return (
      <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex items-center space-x-3">
          <i className="las la-plane text-2xl"></i>
          <CountUp
            start={0}
            end={miles}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">miles</span>
              </span>
            )}
          </CountUp>

        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-suitcase text-2xl"></i>
          <CountUp
            start={0}
            end={tripsCount}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">trips</span>
              </span>
            )}
          </CountUp>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-handshake text-2xl"></i>
          <CountUp
            start={0}
            end={matchesCount}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">matches</span>
              </span>
            )}
          </CountUp>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Meet With - User Page</title>
      </Helmet>
      <main className="container mt-12 flex flex-col">
        {/* <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row"> */}
        <div className="block flex-grow mb-10 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        {userData?.trips?.length ? <div className="lg:mt-16 ">
          <h2 className="text-3xl font-semibold">User Trips</h2>
          <div className={`mt-10 mb-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-6 md:gap-8`}>
            {userData?.trips.map((item, i) => (
              <UserTripCard key={i} trip={item} />
            ))}
          </div>
        </div> : null}

        {/* <div className="w-full lg:w-3/5 xl:w-2/3 flex-shrink-0"> */}
        {/* {renderSection1()} */}
        {/* {renderSection2()} */}
        {/* </div> */}
      </main>
      <FooterNav />
    </div>
  );
};

export default observer(UserPage)
