import { AxiosResponse } from 'axios';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export async function getMatches(): Promise<AxiosResponse<PaginationResult<any>>> {
   const url = `${process.env.REACT_APP_API_URL}/matches`
   return axiosInstance.get(url, { withCredentials: true })
}

export async function getPotentialMatches(country: string, matchLevel: 'High' | 'Medium' | 'Low' = 'High'): Promise<AxiosResponse<any>> {
   const url = `${process.env.REACT_APP_API_URL}/matches/potential?country=${country}&level=${matchLevel}`
   return axiosInstance.get(url, { withCredentials: true })
}

export async function submitUserMatchInteraction(targetUserId: string, interactionType: 'L' | 'D'): Promise<AxiosResponse<any>> {
   const url = `${process.env.REACT_APP_API_URL}/matches`;

   return axiosInstance.post(url, {
      targetUserId,
      interactionType,
   }, { withCredentials: true });
}


export const sendConnectionRequest = async (receiverId: string) => {
   try {
      if (!receiverId) throw new Error('No receiverId provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/connection/request`, { receiverId }, { withCredentials: true });
      console.log('Connection request sent:', response.data);
   } catch (error: any) {
      console.error('Error sending connection request:', error?.response?.data || error.message);
   }
};

export const sendEnterpriseMatchSearchRequest = async (searchCriteria: string) => {
   try {
      if (!searchCriteria) throw new Error('No search criteria provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/matches/ai/enterprise/search`, { searchCriteria }, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendMatchSearchRequest = async (searchCriteria: string) => {
   try {
      if (!searchCriteria) throw new Error('No search criteria provided');
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/matches/ai/opportunities/search`, { searchCriteria }, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendQuickMatchSearchRequest = async () => {
   try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/matches/ai/opportunities/quick-search`, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

export const sendEnterpriseQuickMatchSearchRequest = async () => {
   try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/matches/ai/enterprise/quick-search`, { withCredentials: true });
      console.log('Match search request sent:', response.data);
      return response.data
   } catch (error: any) {
      console.error('Error sending match search request:', error?.response?.data || error.message);
   }
};

