import { fetchNotificationsFromAPI, fetchUnreadNotifications, INotification } from "api/notifications";
import { makeAutoObservable } from "mobx";

class NotificationStore {
    notifications: INotification[] = [];
    hasUnreadMessages: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async chekForUnreadMessages() {
        // Replace this with your actual API call
        const { data: fetchedNotifications } = await fetchUnreadNotifications();
        this.hasUnreadMessages = fetchedNotifications;
    }

    // Function to pull notifications
    async pullNotifications() {
        // Replace this with your actual API call
        const { data: fetchedNotifications } = await fetchNotificationsFromAPI();
        this.notifications = fetchedNotifications;
    }

    // Function to remove a notification
    removeNotification(id: string) {
        this.notifications = this.notifications.filter(notification => notification.id !== id);
    }

    // Function to set a notification as seen
    setAsSeen(id: string) {
        this.notifications = this.notifications.map(notification =>
            notification.id === id ? { ...notification, seen: true } : notification
        );
    }
}

// Instantiate the store
export const notificationStore = new NotificationStore();
