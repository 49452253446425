import { MapPinIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect, useRef, FC } from "react";
import _ from 'lodash'

interface Props {
  onClick?: () => void;
  onChange?: (value: google.maps.places.AutocompletePrediction) => void;
  className?: string;
  defaultValue?: string;
  headingText?: string;
}

const LocationInput: FC<Props> = ({
  onChange = () => {},
  className = "",
  defaultValue = "",
  headingText = "Where to?",
}) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const containerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const fetchPlaces = _.debounce((inputValue: string) => {
    if (!window.google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    if (inputValue.length > 2) {
      service.getPlacePredictions({ input: inputValue, types: ['(cities)'], language: 'en' }, (predictions, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
          console.error(status);
          return;
        }

        setSuggestions(predictions);
      });
    } else {
      setSuggestions([]);
    }
  }, 300)

  useEffect(() => {
    fetchPlaces(value);
  }, [value]);

  const handleSelectLocation = (item: google.maps.places.AutocompletePrediction) => {
    setValue(item.description);
    onChange && onChange(item);
  };

  const renderSearchValues = ({
    heading,
    items,
  }: {
    heading: string;
    items: google.maps.places.AutocompletePrediction[];
  }) => {
    return (
      <>
        <p className="block font-semibold text-base">
          {heading || "Destinations"}
        </p>
        <div className="mt-3">
          {items.map((item) => {
            return (
              <div
                className="py-2 mb-1 flex items-center space-x-3 text-sm"
                onClick={() => handleSelectLocation(item)}
                key={item.place_id}
              >
                <MapPinIcon className="w-5 h-5 text-neutral-500 dark:text-neutral-400" />
                <span className="">{item.description}</span>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div className={`${className}`} ref={containerRef}>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {headingText}
        </span>
        <div className="relative mt-5">
          <input
            className={`block w-full bg-transparent border px-4 py-3 pr-12 border-neutral-900 dark:border-neutral-200 rounded-xl focus:ring-0 focus:outline-none text-base leading-none placeholder-neutral-500 dark:placeholder-neutral-300 truncate font-bold placeholder:truncate`}
            placeholder={"Search destinations"}
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className="absolute right-2.5 top-1/2 -translate-y-1/2">
            <MagnifyingGlassIcon className="w-5 h-5 text-neutral-700 dark:text-neutral-400" />
          </span>
        </div>
        <div className="mt-7">
          {value
            ? renderSearchValues({
                heading: "Locations",
                items: suggestions,
              })
            : renderSearchValues({
                heading: "Popular destinations",
                items: suggestions,
              })}
        </div>
      </div>
    </div>
  );
};

export default LocationInput;
