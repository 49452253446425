import Lottie from "react-lottie";
import animationLoader from './Flight-Loader.json'

export const Loader = () => {
    const flightOptions = {
        loop: true,
        autoplay: true,
        animationData: animationLoader,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Slightly dim the background
            zIndex: 1000, // Ensure it is above other elements
        }}>
            <Lottie options={flightOptions} height={200} width={200} />
            <h1 style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '20px',
                color: '#333',
            }}>
                Loading...
            </h1>
        </div>
    );
};