import { fetchCommunityData } from "api/communities";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import { CommunityData } from "interface/communities";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommunityDetailPageContainer } from "./CommunityDetailPage";

const CommunityPagetLayout = () => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");

  const { community_id } = useParams<{ community_id: string }>();

  const [communityData, setCommunityData] = useState<CommunityData | null>(null);

  useEffect(() => {
    if (community_id) {
      fetchCommunityData(community_id).then((response) => {
        const { data } = response;
        setCommunityData(data || null);
      });
    }
  }, [community_id]);

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if (!communityData?.gallery) {
      return [];
    }
    return [...communityData?.gallery].map((url, index) => {
      return {
        id: index,
        url: url,
      };
    });
  };

  return (
    <div className="ListingDetailPage mb-24">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">
        <CommunityDetailPageContainer communityData={communityData} />
      </div>
      {/* OTHER SECTION */}
      {/* <div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div> */}

      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};
export default CommunityPagetLayout;
