import googleSvg from "images/Google.svg";
import { observer } from "mobx-react";
import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import { BeatLoader, ClimbingBoxLoader } from "react-spinners";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { authStore } from "stores/AuthStore";
import connections from '../../images/lottie/connections.json';

export interface PageLoginProps {
  className?: string;
}

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: connections,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [email, setEmail] = useState('')
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    if (authStore.currentUser) {
      navigate('/')
    }
  }, [authStore.currentUser])

  const handleGoogleSignIn = async () => {
    authStore.setLoading(true);
    try {
      await authStore.googleSignIn()
    } catch (error) {
      console.log(error);
    } finally {
      authStore.setLoading(false);
    }
  }

  // Handler for input changes
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true);
    await authStore.sendSignInLinkToEmailHandler(email)
    setIsLoading(false);
    setIsMagicLinkSent(true);
    setEmail('')
    toast.success('Verification email has been sent!');
  };

  return (
    <div className={`nc-PageLogin ${className} h-dvh mb-[100px] md:mb-0`} data-nc-id="PageLogin">
      {authStore.loading ? <div className="flex items-center justify-center h-screen">
        <ClimbingBoxLoader color="#bb86fc" />
      </div> :
        <div>
          <Helmet>
            <title>Access Your Account || Booking React Template</title>
          </Helmet>
          <div className="container mb-24 lg:mb-32">
            <style>
              {`
          @keyframes shine {
            0% { background-position: -200%; }
            100% { background-position: 200%; }
          }
          
          .shiny-text {
            position: relative;
            display: inline-block;
            background: linear-gradient(90deg, #4b9cdb, #2563eb, #4b9cdb);
            background-size: 200%;
            background-clip: text;
            color: transparent;
            animation: shine 3s linear infinite;
            font-weight: bold;
          }

          .shiny-text::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: inherit;
            z-index: -1;
            border-radius: 8px;
            filter: blur(8px);
          }
        `}
            </style>
            <div className="flex justify-center">
              <h2 className="my-3 text-4xl md:text-6xl font-bold text-center leading-relaxed md:leading-loose relative text-blue-700 dark:text-blue-300 shiny-text">
                Intelligent Connections
              </h2>
            </div>
            <div className="flex justify-center">
              <Lottie options={lottieOptions} height={100} width={300} />
            </div>
            <div className="max-w-md mx-auto space-y-6">
              <span className="my-8 flex items-center text-base md:text-lg text-neutral-700 dark:text-neutral-300 text-center font-medium leading-relaxed">
                Connect with like-minded professionals during your travels. Share your journey, create meaningful connections, and grow your opportunities.
              </span>

              <div className="grid gap-3">
                {loginSocials.map((item, index) => (
                  <> <button
                    key={index}
                    className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      className="flex-shrink-0"
                      src={item.icon}
                      alt={item.name}
                    />
                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                      {item.name}
                    </h3>
                  </button>
                    <p className="text-xs text-neutral-500 dark:text-neutral-400 text-center">
                      Please use your work email for a better experience.
                    </p>
                  </>
                ))}
              </div>
              {/* OR */}
              <div className="relative flex items-center mt-4">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-4 text-gray-500">OR</span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>
              {/* FORM */}
              <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Magic Link
                  </span>
                  <Input
                    type="email"
                    placeholder="Use your work email"
                    className="mt-1"
                    onChange={handleEmailChange}
                  />
                  {isMagicLinkSent && (
                    <p className="mt-4 p-2">
                      Verification email sent! Please check your inbox for a message with
                      a magic link to verify your account. Simply click on the link to
                      complete the verification process.
                    </p>
                  )}
                </label>

                {/* Render Continue button only if the email field is not empty */}

                <ButtonPrimary loading={isLoading} type="submit">
                  Get Login Link
                </ButtonPrimary>

              </form>

              {/* ==== */}
              {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span> */}
            </div>
          </div>
        </div>}
    </div>
  );
};

export default observer(PageLogin)
