import { CustomLink } from "data/types";
import React, { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import NextPrev from "shared/NextPrev/NextPrev";

export interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  fontClass?: string;
  desc?: ReactNode;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  viewAll?: CustomLink;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  desc = "Popular places to stay that Chisfis recommends for you",
  className = "mb-10 lg:mb-16 text-neutral-900 dark:text-neutral-50",
  isCenter = false,
  hasNextPrev = false,
  viewAll,
  ...args
}) => {
  return (
    <div className={`nc-Section-Heading relative flex sm:flex-row justify-between ${className}`}>
      <div className={isCenter ? "text-center w-full max-w-2xl mx-auto" : "max-w-2xl"}>
        <h2 className={`text-xl md:text-4xl font-semibold`} {...args}>
          {children || `Section Heading`}
        </h2>
        {desc && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {desc}
          </span>
        )}
      </div>
      {!!viewAll?.href && (
        <div>
          <Link
            className="flex-shrink-0 block text-primary-700 dark:text-primary-500 font-semibold text-sm "
            target={viewAll.targetBlank ? "_blank" : undefined}
            rel="noopener noreferrer"
            to={viewAll.href}
          >
            {viewAll.label}
          </Link>
          </div>
        )}
      {hasNextPrev && !isCenter && (
        <div className="mt-4 flex justify-end sm:ml-2 sm:mt-0 flex-shrink-0">
          <NextPrev onClickNext={() => { }} onClickPrev={() => { }} />
        </div>
      )}
    </div>
  );
};

export default Heading;
