// Import necessary libraries and modules
import { fetchCommunityData } from 'api/communities';
import { addDoc, collection, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter } from 'firebase/firestore';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { authStore } from 'stores/AuthStore';
import { firestore } from '../../firebase';


const CommunityGroupChat = () => {
    const location = useLocation();
    const [messages, setMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const [lastVisible, setLastVisible] = useState<any>();
    const [hasMore, setHasMore] = useState(true);
    const [communityName, setCommunityName] = useState('');
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const currentUser = authStore.currentUser

    const community_id = queryParams.get('community_id');

    useEffect(() => {
        if (community_id) {
            fetchCommunityData(community_id).then((response) => {
                const { data } = response;

                if (data.communityUsers?.length === 0) {
                    toast.info('Join the community to chat with other members', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    return
                }

                data.communityUsers?.find((user) => {
                    if (user.user.id === currentUser?.id) {
                        setCommunityName(data.name);
                        loadInitialMessages();
                    } else {
                        toast.error('You are not part of this community', {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                            icon: '😕'
                        });
                        navigate('/');
                    }
                });
            });
        }
    }, [community_id]);


    const loadInitialMessages = async () => {
        const q = query(
            collection(firestore, 'communities', community_id || '', 'messages'), // Access the 'messages' subcollection within the community document
            orderBy('timestamp', 'desc'),
            limit(20)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                // If no messages exist, create an initial message
                createInitialMessage();
            } else {
                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(messagesData.reverse());
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                setHasMore(snapshot.docs.length === 20);
            }
        });

        return () => unsubscribe();
    };


    const createInitialMessage = async () => {
        const messagesCollection = collection(firestore, 'communities', community_id || '', 'messages');
        await addDoc(messagesCollection, {
            text: 'Welcome to the chat!',
            uid: 'system',
            displayName: 'System',
            timestamp: serverTimestamp(),
        });
    };

    const loadMoreMessages = async () => {
        console.log('Loading more messages...');

        if (!lastVisible) return;
        const q = query(
            collection(firestore, 'communities', community_id || '', 'messages'),
            orderBy('timestamp', 'desc'),
            startAfter(lastVisible),
            limit(20)
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMessages((prevMessages) => [...newMessages.reverse(), ...prevMessages]);
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === 20);
        });

        return () => unsubscribe();
    };

    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        if (!currentUser?.id || !currentUser?.profile) {
            console.error('User data is missing.');
            return;
        }

        const { id, profile } = currentUser;
        await addDoc(collection(firestore, 'communities', community_id || '', 'messages'), {
            text: newMessage,
            id,
            displayName: profile.fullName,
            timestamp: serverTimestamp(),
            avatar: profile.avatar,
        });

        setNewMessage('');
        scrollToBottom();
    };

    const handleNameClick = (id: string) => {
        navigate(`/profile/${id}`);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="flex flex-col h-screen p-4 bg-gray-100">
            <header className="flex items-center justify-between p-4 bg-white shadow-md">
                <button
                    onClick={() => navigate(-1)}
                    className="text-blue-500 hover:underline"
                >
                    <i className="las la-arrow-left mr-2"></i>
                </button>
                <h1 className="text-xl font-bold text-gray-800">{communityName}</h1>
                <div />
            </header>
            <div id="scrollableDiv" className="flex-grow overflow-y-auto mb-20 mt-4">
                <InfiniteScroll
                    dataLength={messages.length}
                    next={loadMoreMessages}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDiv"
                    inverse={true}
                >
                    {messages.map(({ id, displayName, text, timestamp, uid, avatar }, index) => {
                        const user = currentUser?.profile;
                        return (
                            <div key={index} className="flex flex-col mb-2 p-2 bg-white rounded shadow-md">
                                <div className="flex items-center space-x-2">
                                    {user?.avatar && (
                                        <Avatar imgUrl={avatar} containerClassName="w-8 h-8 rounded-full" />
                                    )}
                                    <button
                                        onClick={() => handleNameClick(uid)}
                                        className="text-blue-600 font-semibold hover:underline"
                                    >
                                        {displayName}
                                    </button>
                                    <span className="text-gray-500 text-sm">
                                        {moment(timestamp?.seconds * 1000).format('LT, MMM D YYYY')}
                                    </span>
                                </div>
                                <p className="text-gray-700 mt-1">{text}</p>
                            </div>
                        );
                    })}
                </InfiniteScroll>
                <div ref={messagesEndRef} />
            </div>
            {/* Input form fixed to the bottom */}
            <form
                onSubmit={handleSendMessage}
                className="fixed bottom-0 left-0 w-full flex items-center p-4 bg-white border-t"
                style={{ maxWidth: '100%' }}
            >
                <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    className="flex-grow p-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2 h-12"
                    placeholder="Type your message here..."
                />
                <ButtonPrimary
                    type="submit"
                    className="h-12 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 flex items-center justify-center"
                >
                    Send
                </ButtonPrimary>
            </form>
        </div>
    );
}

export default observer(CommunityGroupChat);
