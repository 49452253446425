import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { deletePlaceById, getTripDetailsById, postCommentToTrip, updateTrip, UpdateTripBody } from "api/trip";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import FooterNav from "components/FooterNav";
import StartRating from "components/StartRating/StartRating";
import { TripDetails } from "interface/trip.interface";
import * as moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from 'shared/Comment/Comment';
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import Textarea from "shared/Textarea/Textarea";
import AddPlaceBtn from "./AddPlaceBtn";
import EditTripModal from "./HeroButtons";
import RentalItineraryDatesRangeInput from "./RentalItineraryDatesRangeInput";
import { Amenities_demos, includes_demo } from "./constant";
import Card13 from "containers/BlogPage/Card13";
import { DEMO_POSTS } from "data/posts";

const ListingItineraryDetail: FC<{}> = () => {
  // State to store itinerary data
  const [tripData, setTripData] = useState<TripDetails | null>(null);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Extract ID from URL params
  const { id } = useParams<{ id: string }>();
  
  // Function to fetch itinerary data
  const fetchItineraryData = async () => {
    try {
      const { data: tripDetailes } = await getTripDetailsById(id)
      setTripData(tripDetailes);
    } catch (error) {
      console.error("Failed to fetch itinerary data", error);
      // Handle error appropriately
    }
  };

  // useEffect to trigger data fetching
  useEffect(() => {
    if (id) {
      fetchItineraryData();
    }
  }, [id]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value);
  };

  const handleCommentSubmit = async () => {
    if (!tripData) {
      return
    }
    setIsSubmitting(true);
    try {
      const { data } = await postCommentToTrip(tripData.id, comment);
      // Update the comments state
      setTripData((prevTrip) => ({
        ...(prevTrip as TripDetails),
        comments: prevTrip ? [...(prevTrip?.comments || []), data] : [comment],
      }));
      toast.success('Comment posted successfully');
    } catch (error) {
      toast.error('Failed to post comment');
      // handle error
      console.error(error);
    } finally {
      setIsSubmitting(false);
      setComment('');
    }
  };

  const handleScheduleUrl = async (url: string, action: 'add' | 'remove') => {
    // try {
    //   if (!tripData) {
    //     return
    //   }
    //   if (action === 'add') {
    //     await addScheduleUrl(tripData?.id, url)
    //     toast.success("Schedule URL added successfully");
    //     setTripData({ ...tripData, scheduleUrl: url });
    //   } else if (action === 'remove') {
    //     await removeScheduleUrl(tripData?.id)
    //     toast.success("Schedule URL removed successfully");
    //     setTripData({ ...tripData, scheduleUrl: undefined });
    //   }
    // } catch (error) {
    //   console.error("Failed to handle schedule URL", error);
    //   // Handle error appropriately
    // }
  };

  const habdleEditTrip = async (data: UpdateTripBody) => {
    if (!id) {
      return
    }
    await updateTrip(id, data);
    toast.info("Trip updated successfully");
    setTripData((prevData) => {
      if (prevData) {
        return {
          ...prevData,
          ...data,
        };
      }
      return prevData;
    });
  }

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge color="green" name={tripData?.visibility} />
          <EditTripModal prevDescription={tripData?.description} scheduleUrl={tripData?.scheduleUrl} habdleEditTrip={habdleEditTrip}/>
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {tripData?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {tripData?.toPlaceDescription}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={tripData?.author?.profile?.avatar} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            {/* Car owner{" "} */}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {tripData?.author?.profile?.fullName}
            </span>
          </span>
        </div>
        <div className="flex items-center">
          {tripData?.scheduleUrl && (<div>
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              Book a meeting
            </span>
            <Badge
              className="mt-2"
              color="indigo"
              href={tripData?.scheduleUrl}
              name={tripData?.scheduleUrl?.includes('calendar.app.google') ? 'With Google' : 'With Calendly'}
              children={<i className="ml-1 las la-calendar text-base"></i>}
            /></div>)}
          <div>
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {tripData?.description}
            </span>
          </div>
        </div>

        {/* 5 */}
        {/* <div className="w-full border-b border-neutral-100 dark:border-neutral-700" /> */}

        {/* 6 */}
        {/* {itineraryData?.tripSafety &&
          <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-hand-lizard text-2xl"></i>
              <span className="">{itineraryData?.tripSafety.theft}</span>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-briefcase-medical text-2xl"></i>
              <span className="">{itineraryData?.tripSafety.medical}</span>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-handshake text-2xl"></i>
              <span className="">{itineraryData?.tripSafety.politicalFreedom}</span>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-check-double text-2xl"></i>
              <span className="">{itineraryData?.tripSafety.overall}</span>
            </div>
          </div>
        } */}
      </div>
    );
  };

  //
  const renderSectionTienIch = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">
            Vehicle parameters & utilities{" "}
          </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Questions are at the heart of making things great.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10 text-sm text-neutral-700 dark:text-neutral-300 ">
          {/* TIEN ICH 1 */}
          {Amenities_demos.map((item, index) => (
            <div key={index} className="flex items-center space-x-4 ">
              <div className="w-10 flex-shrink-0">
                <img src={item.icon} alt="" />
              </div>
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Car descriptions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>
            Until the all-new TUCSON hits the dealer showrooms you can check it
            out in our Showroom Walkaround video. Watch the video and join our
            product specialist as he gives you an up-close look of our latest
            SUV
            <br />
            <br />
            Questions are at the heart of making things great. Watch our
            celebrity-filled TV ad and you’ll see that when we say “everything,”
            we mean everything.
          </p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Car Owner</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Lock in this fantastic price today, cancel free of charge anytime.
            Reserve now and pay at pick-up.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            We asked ourselves, “How can we make the dash not only look better,
            but also give the driver a better look outside?” The unexpected
            answer is having no hood above the available 10.25-inch digital
            instrument cluster...
          </span>
        </div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $19
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /day
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="border border-neutral-200 dark:border-neutral-700 rounded-2xl">
          <RentalItineraryDatesRangeInput />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4 ">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>$19 x 3 day</span>
            <span>$57</span>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>$199</span>
          </div>
        </div>

        {/* SUBMIT */}
        <ButtonPrimary href={"/checkout"}>Reserve</ButtonPrimary>
      </div>
    );
  };

  const renderSidebarDetail = () => {
    /** Need to support dynamic data */
    const handleDeletePlace = async (id: string) => {
      await deletePlaceById(id);
      toast.success("Place deleted successfully");
      setTripData((prevData) => {
        if (prevData) {
          const updatedPlaces = prevData.places.filter((place) => place.id !== id);
          return {
            ...prevData,
            places: updatedPlaces,
          };
        }
        return prevData;
      });
    };

    return (
      <div className="listingSection__wrap lg:shadow-xl">
        <div className="flex items-center justify-between">
          <span className="text-2xl font-semibold block">Schedule</span>
          {tripData && <AddPlaceBtn trip={tripData} setItineraryData={setTripData} />}
        </div>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            {tripData?.places?.map((place, i) => (
              <>
                <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              </>
            ))}
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-8 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {tripData?.startDate ? moment.utc(tripData.startDate).format("dddd, MMMM DD · HH:mm") : ""}
              </span>
              <span className=" font-semibold">{tripData?.toCity}</span>
            </div>
            {tripData?.places?.map((place, i) => (
              <div key={i} className="flex flex-col space-y-2 w-full">
                <div className="flex items-center justify-between space-x-3">
                  <div className="flex items-center space-x-3">
                    <NcImage
                      containerClassName="flex-shrink-0 w-12 h-12 rounded-lg overflow-hidden"
                      src={place.photoUrl}
                    />
                    <div>
                      <span className="text-neutral-500 dark:text-neutral-400 block">
                        {place?.date ? moment.utc(place.date).format("dddd, MMMM DD · HH:mm") : ""}
                      </span>
                      <span className="font-semibold block">{place?.name}</span>
                    </div>
                  </div>
                  <div onClick={() => handleDeletePlace(place.id)} className="cursor-pointer">
                    <i className="las la-trash text-2xl"></i>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                {tripData?.endDate ? moment.utc(tripData.endDate).format("dddd, MMMM DD · HH:mm") : ""}
              </span>
              <span className=" font-semibold">{tripData?.toCity}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Comments ({tripData?.commentsNum})
        </h3>
        <div className="nc-SingleCommentForm mt-5">
          <Textarea value={comment} onChange={handleInputChange} />
          <div className="mt-2 space-x-3">
            <ButtonPrimary loading={isSubmitting} disabled={isSubmitting} onClick={handleCommentSubmit}>{isSubmitting ? '' : 'Submit'}</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentLists = () => {
    if (tripData?.comments?.length === 0) {
      return (
        <div className="max-w-screen-md mx-auto mt-10 text-center text-neutral-500 dark:text-neutral-400">
          No comments yet
        </div>
      );

    }

    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          {tripData?.comments?.map((comment, index) => (
            <li key={index}>
              <Comment {...comment} />
              {/* <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              {comment.replies.map((reply, index) => (
                <li key={index}>
                  <Comment isSmall {...reply} />
                </li>
              ))}
            </ul> */}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className={` nc-ListingCarDetailPage container`}>
      {/* SINGLE HEADER */}

      {/* MAIn */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">

        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}
          <div className="block lg:hidden">{renderSidebarDetail()}</div>
          <div className="grid gap-6 md:gap-8">
          {DEMO_POSTS
            .filter((_, i) => i < 4 && i > 0)
            .map((item, index) => (
              <Card13 key={index} post={item} />
            ))}
        </div>
          {/* {renderSectionTienIch()} */}
          {/* {renderSection2()} */}
          {/* {renderSection3()} */}
          {/* <SectionDateRange />

          {renderSection5()}
          {renderSection6()}
          {renderSection7()}
          {renderSection8()} */}
          {renderCommentForm()}
          {renderCommentLists()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0">
          {/* {renderSidebarDetail()} */}
          <div className="hidden lg:block mt-10 sticky top-28">
            {renderSidebarPrice()}
          </div>
        </div>
      </main>
      <FooterNav />
    </div>
  );
};

export default function ListingItineraryDetailPage() {
  return (
    <ListingItineraryDetail />
  );
}
