"use client";

import React, { FC } from "react";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
  onClick?: () => void;
}

const ButtonSubmit: FC<Props> = ({
  className = "",
  onClick
}) => {

  const handleClick = (e:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onClick?.()
  }
  return (
    <div
      className={`h-14 w-14 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none ${className}`}
      onClick={handleClick}
    >
      <span className="mr-3 md:hidden">Search</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
      </svg>
    </div>
  );
};

export default ButtonSubmit;
