import { AxiosResponse } from 'axios';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export const VC_FIRMS_BASE_URL = `${process.env.REACT_APP_API_URL}/venture-capital-firm`

export class VentureCapitalFirmFilterDto {
  id?: number;
  vcFirm?: string;
  website?: string;
  countries?: string;
  cities?: string;
  industries?: string;
  stages?: string;
  foundedYear?: string;
  founders?: string;
  numberOfInvestments?: number;
  numberOfExits?: number;
  minCheckSize?: string;
  maxCheckSize?: string;
  fundsRaised?: string;
  contactInformation?: string;
  logo?: string;
}

export async function fetchVcFirmsByUrl(url: string): Promise<AxiosResponse<PaginationResult<VentureCapitalFirmFilterDto[]>>> {
  return axiosInstance.get(url, { withCredentials: true });
}

export function fetchVcById(id: string): Promise<AxiosResponse<VentureCapitalFirmFilterDto>> {
  return axiosInstance.get(`${VC_FIRMS_BASE_URL}/${id}`, { withCredentials: true });
}
