import { observer } from "mobx-react";
import { FC } from "react";
import Logo from "shared/Logo/Logo";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {
  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex items-center justify-between">
        {/* Left placeholder */}
        <div className="flex-1 hidden md:block"></div>

        {/* Centered Logo */}
        <div className="flex justify-center flex-1">
          <Logo className="w-34" />
        </div>

        {/* Right placeholder */}
        <div className="flex-1 hidden md:block"></div>
      </div>
    </div>
  );
};

export default observer(MainNav1);
