import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { UpdateTripBody } from "api/trip";
import Label from "components/Label/Label";
import { Fragment, useEffect, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

interface EditTripModalProps {
  scheduleUrl: string | undefined,
  prevDescription: string | undefined,
  // handleScheduleUrl: (url: string, action: 'add' | 'remove') => void;
  habdleEditTrip: (date: UpdateTripBody) => void;
}

const EditTripModal: React.FC<EditTripModalProps> = ({ scheduleUrl: initialScheduleUrl, habdleEditTrip, prevDescription }) => {
  const [scheduleUrl, setScheduleUrl] = useState(initialScheduleUrl);

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const [description, setDescription] = useState(prevDescription);

  const openModalMoreFilter = () => setisOpenMoreFilter(true)
  const closeModalMoreFilter = () => setisOpenMoreFilter(false)

  useEffect(() => {
    setDescription(prevDescription)
  },[prevDescription])

  useEffect(() => {
    setScheduleUrl(initialScheduleUrl)
  },[scheduleUrl])

  const handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };

  const handleChange = (event: any) => {
    const url = event.target.value;

    try {
      new URL(url);
      setScheduleUrl(url);
    } catch (_) {
      console.error("Invalid URL");
      // Handle invalid URL appropriately
    }
  };

  const handleSubmit = () => {
    const date = {
      scheduleUrl,
      description
    };
    habdleEditTrip(date)
    // scheduleUrl && handleScheduleUrl(scheduleUrl, 'add');
  };

  const handleRemoveSubmit = () => {
    setScheduleUrl(undefined)
    // handleScheduleUrl('', 'remove');
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={(v) => console.log(v)}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Edit Trip
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          Explain Your Trip's Objectives
                        </h3>

                        <Textarea
                          className="mt-1"
                          rows={6}
                          value={description}
                          onChange={handleMessageChange}
                          placeholder="Describe your trip's main objectives..."
                          required
                        />
                      </div>
                      <div className="py-7">

                        {/* <Label>Trip purpose</Label> */}

                        <h3 className="text-xl font-medium">
                          Share your Calendly or Google appointment link
                        </h3>
                        <div className="mt-6 relative ">
                          <Input
                            defaultValue={scheduleUrl}
                            fontClass=""
                            sizeClass="h-16 px-4 py-3"
                            rounded="rounded-3xl"
                            placeholder="Copy your link here ..."
                            value={scheduleUrl}
                            onChange={handleChange}
                          />
                          {/* {!scheduleUrl ? <ButtonCircle
                            className="absolute right-2 top-1/2 transform -translate-y-1/2"
                            size=" w-12 h-12 "
                            onClick={handleSubmit}
                          >
                            <ArrowRightIcon className="w-5 h-5" />
                          </ButtonCircle> :
                            <ButtonCircle
                              className="absolute right-2 top-1/2 transform -translate-y-1/2"
                              size=" w-12 h-12 "
                              onClick={handleRemoveSubmit}
                            >
                              <XMarkIcon className="w-5 h-5" />
                            </ButtonCircle>} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        handleRemoveSubmit()
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        handleSubmit()
                        closeModalMoreFilter()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5 ">
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={openModalMoreFilter}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span>
        {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Save</span>
        </span> */}
        <div onClick={openModalMoreFilter}>
          <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
            </svg>
          </span>
        </div>
        {renderTabMoreFilter()}
      </div>
    </div>
  );
};

export default EditTripModal;
