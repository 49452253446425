import { BuildingLibraryIcon, StarIcon } from "@heroicons/react/24/outline";
import { TwMainColor } from "data/types";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import DropSvg from "svg/drop";
import EcoSvg from "svg/eco";
import FireSvg from "svg/fire";

export interface CardLikeMindedLocalsBoxProps {
  className?: string;
  likeMindedLocal: any;
  index?: number;
}

const CardLikeMindedLocalsBox: FC<CardLikeMindedLocalsBoxProps> = ({
  className = "",
  likeMindedLocal,
  index,
}) => {
  const { id, displayName, avatar, city, role, industry, matchScore, companyName } = likeMindedLocal;

  const getSvgByScore = (matchScore: number) => {
    if (matchScore >= 7) {
      return { SvgComponent: FireSvg, color: 'red' as TwMainColor }; // High score
    } else if (matchScore >= 4) {
      return { SvgComponent: EcoSvg, color: 'green' as TwMainColor }; // Medium score
    } else {
      return { SvgComponent: DropSvg, color: 'blue' as TwMainColor }; // Low score
    }
  };

  const { SvgComponent, color } = getSvgByScore(matchScore);

  const getCompanyName = (companyName: string) => {
    if (!companyName) {
      return null;
    }
    return (<div className="py-2 px-5 mt-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ">
      <span className="text-xs font-medium pt-[1px]">
        {companyName}
      </span>
      <BuildingLibraryIcon className="w-5 h-5  ml-2 " />
    </div>)
  };

  return (
    <Link
      to={`/user/${id}`}
      className={`nc-CardTripBox relative flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7 h-64 w-60 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardTripBox"
    >
      {index && (
        <Badge
          className="absolute left-3 top-3"
          color={color}
          name=""
        >
          <SvgComponent color={color} />
        </Badge>
      )}
      <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        imgUrl={avatar}
        userName={displayName}
      />
      <div className="mt-3">
        <h2 className={`text-base font-medium`}>
          <span className="line-clamp-1">{displayName}</span>
        </h2>
        <span className={`block mt-1.5 text-xs text-neutral-500 dark:text-neutral-400`}>{city}</span>
        <span className={`block mt-1.5 text-sm text-neutral-500 dark:text-neutral-400`}>{role} | {industry}</span>
      </div>
      {getCompanyName(companyName)}
    </Link>
  );
};

export default CardLikeMindedLocalsBox;
