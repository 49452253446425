import CardLikeMindedLocalsBox from "components/CardLikeMindedLocalsBox/CardLikeMindedLocalsBox";
import useNcId from "hooks/useNcId";
import { FC } from "react";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
import CommunityCard from "./CommunityCard";

export interface Community {
  id: string;
  name: string;
  logo?: string;
  description?: string;
  country: string;
  city?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  contactEmail?: string;
  phone?: string;
  website?: string;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  amenities?: string[];
  gallery?: string[];
  workspaceType?: string;
  capacity?: number;
  pricing?: string;
  about?: string;
}

export interface CommunitiesSliderProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  communities?: Community[];  // Updated to use Community type
  itemPerRow?: 4 | 5;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  uniqueClassName: string;
}

const CommunitiesSlider: FC<CommunitiesSliderProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  communities = [],
  itemPerRow = 5,
  hasNextPrev = true,
  isCenter = false,
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "CommunitiesSlider" + uniqueClassName + useNcId();

  return (
    <div className={`nc-CommunitiesSlider ${className}`}>
      <h2>{heading}</h2>
      <p>{subHeading}</p>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Swiper
          className="w-full h-full mySwiper"
          slidesPerView={1.5}
          spaceBetween={30}
          breakpoints={{
            1280: {
              slidesPerView: itemPerRow - 1,
            },
            1024: {
              spaceBetween: 20,
              slidesPerView: itemPerRow - 1,
            },
            768: {
              spaceBetween: 20,
              slidesPerView: itemPerRow - 2,
            },
            640: {
              spaceBetween: 20,
              slidesPerView: itemPerRow - 3,
            },
            500: {
              spaceBetween: 20,
              slidesPerView: 1.5,
            },
          }}
        >
          {communities.map((community, index) => (
            <SwiperSlide key={community.id} className="flex justify-center items-center text-center text-lg">
              <CommunityCard index={index + 1} community={community} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CommunitiesSlider;
