import { AxiosError, AxiosResponse } from 'axios';
import { ActivityResponse, AttendanceStatus } from 'interface/activity';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export const ACTIVITIES_BASE_URL = `${process.env.REACT_APP_API_URL}/activities`
export interface CreateActivityData {
    title: string;
    description: string;
    startDate: string; // ISO format
    startTime: string; // ISO format
    endDate?: string | null; // Optional, ISO format
    endTime?: string | null; // Optional, ISO format
    timezone: string;
    country: string; // Country of the activity
    city: string; // City of the activity
    state?: string; // Optional state of the activity
    lat: string; // Latitude of the activity location
    long: string; // Longitude of the activity location
    placeDescription: string; // Full address or place description
    placeId?: string; // Optional place ID from Google Maps
    activityType: string;
    file?: File | null; // Optional file, if needed
}


export interface JoinActivityDto {
    activityId: number;
    status: AttendanceStatus;
}


// export async function fetchLocalCommunities(country: string): Promise<AxiosResponse<PaginationResult<any[]>>> {
export async function fetchActivityData(community_id: string): Promise<AxiosResponse<ActivityResponse>> {
    return axiosInstance.get(`${ACTIVITIES_BASE_URL}/${community_id}`, { withCredentials: true })
}

export async function fetchActivityDataByActivtyId(activity_id: string): Promise<AxiosResponse<ActivityResponse>> {
    return axiosInstance.get(`${ACTIVITIES_BASE_URL}/${activity_id}/info`, { withCredentials: true })
}

export async function fetchActivityTypes(): Promise<AxiosResponse<string[]>> {
    return axiosInstance.get(`${ACTIVITIES_BASE_URL}/types`, { withCredentials: true })
}

export async function createActivity(activityData: CreateActivityData): Promise<AxiosResponse<ActivityResponse>> {
    const formData = new FormData();

    // Append all data to formData
    formData.append("title", activityData.title);
    formData.append("description", activityData.description);
    formData.append("startDate", activityData.startDate);
    formData.append("startTime", activityData.startTime);
    if (activityData.endDate) {
        formData.append("endDate", activityData.endDate);
    }
    if (activityData.endTime) {
        formData.append("endTime", activityData.endTime);
    }
    formData.append("timezone", activityData.timezone);
    formData.append("country", activityData.country);
    formData.append("city", activityData.city);
    if (activityData.state) {
        formData.append("state", activityData.state);
    }
    formData.append("lat", activityData.lat);
    formData.append("long", activityData.long);
    formData.append("placeDescription", activityData.placeDescription);
    if (activityData.placeId) {
        formData.append("placeId", activityData.placeId);
    }
    formData.append("activityType", activityData.activityType);
    if (activityData.file) {
        formData.append("file", activityData.file);
    }

    return axiosInstance.post(`${ACTIVITIES_BASE_URL}/create`, formData, {
        withCredentials: true,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export async function fetchLocalActivities(country: string): Promise<AxiosResponse<PaginationResult<ActivityResponse[]>>> {
    return axiosInstance.get(`${ACTIVITIES_BASE_URL}/country/${country}`, { withCredentials: true })
}


export async function handleJoinActivity(activityId: number, status: AttendanceStatus): Promise<AxiosResponse<ActivityResponse>> {
    try {
        if (!activityId) {
            console.error('Activity ID is missing');
            throw new Error('Activity ID is missing');
        }
        // Define the request payload
        const joinActivityDto: JoinActivityDto = {
            activityId,
            status,
        };

        // Make the HTTP POST request
        const response = await axiosInstance.post(`${ACTIVITIES_BASE_URL}/join`, joinActivityDto, { withCredentials: true });

        if (response.status === 200) {
            console.log('Successfully joined activity');
        }

        return response;
    } catch (error: any) {
        console.error('Error joining activity:', error.response?.data || error.message);
        throw error;
    }
}

export async function leaveActivity(activityId: number): Promise<AxiosResponse<ActivityResponse>> {
    try {
        const response = await axiosInstance.delete(`${ACTIVITIES_BASE_URL}/${activityId}/leave`, { withCredentials: true });

        if (response.status === 200) {
            console.log('Successfully left the activity');
        }

        return response;
    } catch (error: any) {
        console.error('Error leaving the activity:', error.response?.data || error.message);
        throw error;
    }
}

export async function deleteActivity(activityId: number): Promise<AxiosResponse<ActivityResponse>> {
    try {
        const response = await axiosInstance.delete(`${ACTIVITIES_BASE_URL}/${activityId}`, { withCredentials: true });

        if (response.status === 200) {
            console.log('Successfully deleted the activity');
        }

        return response;
    } catch (error: any) {
        console.error('Error deleting the activity:', error.response?.data || error.message);
        throw error;
    }
}
