export const converSingleSelectedDateToString = (date: Date | undefined): string => {
  if (date) {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  }
  return "";
};