import React, { useEffect, useState } from 'react';

export const TypingEffect = ({ text, speed = 100 }: { text: string, speed?: number }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timer = setTimeout(() => {
                setDisplayedText((prev) => prev + text[index]);
                setIndex((prev) => prev + 1);
            }, speed);

            return () => clearTimeout(timer);
        }
    }, [index, text, speed]);

    return <span>{displayedText}</span>;
};