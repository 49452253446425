import { User } from "./user.interface";

export interface ActivityResponse {
    id: number;
    title: string;
    description?: string;
    activityType: string;
    chargesType: string;
    startDate: string; // ISO string for the date, can be converted to Date object in frontend
    endDate?: string;  // ISO string, nullable
    startTime: string; // ISO string for the date, can be converted to Date object in frontend
    endTime?: string;  // ISO string, nullable
    timezone?: string; // e.g., 'Asia/Jerusalem'
    image?: string;    // URL or path to the image
    placeDescription?: string;
    placeId?: string;
    placeKeywords?: string[];
    country?: string;
    city?: string;
    state?: string;
    lat?: string;
    long?: string;
    min_participants?: number;
    max_participants?: number;
    author: User;      // Reference to the User entity; adjust as per your frontend's requirements
    attendees: ActivityAttendance[];
}

export enum AttendanceStatus {
    GOING = 'Going',
    INTERESTED = 'Interested',
  }
  
  export interface ActivityAttendance {
    id: number;
    activity: ActivityResponse;
    user: User;
    status: AttendanceStatus;
  }