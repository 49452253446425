import React, { createContext, useState, useContext, ReactNode } from 'react';

interface FilterContextType {
  isQuickMatch: boolean;
  setFilterState: React.Dispatch<React.SetStateAction<FilterContextType>>;
}

const defaultState: FilterContextType = {
  isQuickMatch: false,
  setFilterState: () => {},
};


const FilterContext = createContext<FilterContextType>(defaultState);

export const useFilter = () => useContext(FilterContext);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const [filterState, setFilterState] = useState<FilterContextType>(defaultState);

  return (
    <FilterContext.Provider value={{ ...filterState, setFilterState }}>
      {children}
    </FilterContext.Provider>
  );
};
