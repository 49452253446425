import CardActivityCreator from "components/ActivityCreator/CardActivityCreator";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import { DEMO_AUTHORS } from "data/authors";
import { AuthorType } from "data/types";
import { ActivityResponse } from "interface/activity";
import { User } from "interface/user.interface";
import React, { FC } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridActivityBoxProps {
  className?: string;
  activities?: ActivityResponse[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

function transformUserToAuthor(user: User): AuthorType {
  const [firstName, ...lastNameParts] = user.profile.fullName.split(' ');
  const lastName = lastNameParts.join(' ');

  return {
    id: user.id,
    firstName: firstName,
    lastName: lastName || '',
    displayName: user.profile.fullName,
    avatar: user.profile.avatar || '', // Fallback if avatar is undefined
    bgImage: user.profile.bgImg || undefined,
    email: user.email || undefined,
    count: 0, // This could represent a specific count, set dynamically if needed
    desc: user.profile.bio,
    jobName: user.profile.role,
    href: `/users/${user.id}`, // Assuming a URL format for the user's profile
    starRating: undefined, // Assuming starRating is optional and can be set elsewhere
    country: user.profile.country || undefined
  };
}


const SectionGridActivityBox: FC<SectionGridActivityBoxProps> = ({
  className = "",
  activities = [],
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {

  const navigate = useNavigate();

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc="Connect, Explore, and Grow with New Experiences" isCenter>Join Activities that Spark Growth and Connection</Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {activities.length === 0 ? <NoResultCard displayName="✨ Be the first to create an activity!" /> :
          activities.map((activity, index) =>
            boxCard === "box2" ? (
              <CardActivityCreator key={activity.id} author={transformUserToAuthor(activity.author)} activity={activity} />
            ) : (
              <CardAuthorBox
                index={index < 3 ? index + 1 : undefined}
                key={activity.author.id}
                author={transformUserToAuthor(activity.author)}
              />
            )
          )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        {activities.length >= 10 && (
          <ButtonSecondary>Show me more</ButtonSecondary>
        )}
        <ButtonPrimary onClick={() => navigate('/activity/create')}>Create an Activity</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridActivityBox;
