import { fetchLikeMindedLocals, PROFESSIONAL_ARRIVALS_BASE_URL, ProfessionalProfileDto } from 'api/professionalLocals';
import { fetchNextMonthArrivalsByUrl } from 'api/trip';
import FooterNav from 'components/FooterNav';
import { PaginationLinks, PaginationResult } from 'interface/pagination';
import { TripDetailsResponse } from 'interface/trip.interface';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import BadgeScore from 'shared/BadgeScore/BadgeScore';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface ProfessionalArrivalsPageProps {
  locals?: ProfessionalProfileDto[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}
const ProfessionalArrivalsPage: React.FC<ProfessionalArrivalsPageProps> = ({
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const [arrivals, setProfessionalArrivals] = useState<TripDetailsResponse[]>([]);
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchData(PROFESSIONAL_ARRIVALS_BASE_URL);
  }, []);

  const fetchData = async (url: string) => {
    try {
      const response = await fetchNextMonthArrivalsByUrl(url);
      const data: PaginationResult<TripDetailsResponse[]> = response.data;
      setProfessionalArrivals((prevVcs) => [...prevVcs, ...data.items]);
      setPaginationLinks(data.links);
      setHasMore(!!data.links.next);
    } catch (error) {
      console.error('Failed to fetch VC firms:', error);
    }
  };

  const fetchMoreVcFirms = () => {
    if (paginationLinks && paginationLinks.next) {
      fetchLikeMindedLocals(paginationLinks.next);
    }
  };

  return (
    <div className="flex flex-col justify-center w-full h-full px-4 sm:px-6 lg:px-8 py-16 lg:py-28 bg-neutral-50 dark:bg-neutral-900">
      <InfiniteScroll
        dataLength={arrivals.length}
        next={fetchMoreVcFirms}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p style={{ textAlign: 'center' }}><b>No more firms to show</b></p>}
      >
        <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
          {arrivals.map((arrival, i) => (
            <Link
              key={i}
              to={`/user/${arrival.id}`}
              className={`nc-CardOfProfessional relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]`}
              data-nc-id="CardOfProfessional"
            >
              <BadgeScore
                className="absolute right-2 top-2"
                color="gray"
                name=""
                matchScore={arrival?.matchScore}
              />

              <div className="relative flex-shrink-0 w-12 h-12 rounded-full overflow-hidden">
                <Avatar
                  imgUrl={arrival.avatar}
                  userName={arrival.displayName}
                  sizeClass="h-10 w-10 text-base"
                  containerClassName="flex-shrink-0 mr-3"
                  radius="rounded-full"
                />
                {/* <NcImage src={getAvatar(local)} containerClassName="absolute inset-0" /> */}
              </div>
              <div className="ml-4 flex-grow overflow-hidden">
                <h2 className="text-base font-medium">
                  <span className="line-clamp-1">{arrival.displayName} {`${arrival.companyName ? `(${arrival.companyName})` : ''}`}</span>
                </h2>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>{arrival.industry}</span>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>{arrival.role}</span>
              </div>
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      <FooterNav />
    </div>
  );
};

export default ProfessionalArrivalsPage;
