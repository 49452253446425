// store/SearchFormStore.js
import { SearchTab } from "components/HeroSearchForm/HeroSearchForm";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { makeAutoObservable } from "mobx";

class SearchFormStore {
  
  showHeroSearch: StaySearchFormFields | null = null;
  currentTab: SearchTab = "Add a trip"; // Default tab
  showMobileModal: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setShowHeroSearch(value: StaySearchFormFields | null) {   
    this.showHeroSearch = value;
  }

  setCurrentTab(tab: SearchTab) {
    this.currentTab = tab;
  }

  setShowMobileModal(show: boolean) {    
    this.showMobileModal = show;
  }

  // Additional logic to toggle the search form and optionally set the tab
  toggleSearchForm(formFields = null, tab: SearchTab = "Add a trip") {
    this.showHeroSearch = this.showHeroSearch ? null : formFields;
    this.currentTab = tab;
  }

  // You might want to add a method that specifically handles resetting
  // to default states for scenarios like navigating to a different page
  resetToDefaults() {
    this.showHeroSearch = null;
    this.currentTab = "Add a trip";
  }
}

export const searchFormStore = new SearchFormStore();
