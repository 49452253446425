import React, { FC, useState } from "react";
import LocationInput from "../LocationInput";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import FlightDateRangeInput from "./FlightDateRangeInput";
import { GuestsObject } from "components/HeroSearchForm/type";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import moment from 'moment-timezone'
import { toast } from "react-toastify";
import { sendTripDetailsToBackend } from "api/trip";
import { useNavigate } from "react-router-dom";
import { validateTripDetails } from "validators/trip.validator";
import { TripClassTypes, TripPurposeTypes, TripTypes } from "components/HeroSearchForm2Mobile/(flight-search-form)/FlightSearchForm";


export interface FlightSearchFormProps { }

const flightClass = [
  {
    name: "Economy" as TripClassTypes,
    href: "##",
  },
  {
    name: "Business" as TripClassTypes,
    href: "##",
  },
  {
    name: "First" as TripClassTypes,
    href: "##",
  },
];

// Interface for the component's state
export interface BusinessTripState {
  flyingFrom: google.maps.places.AutocompletePrediction | null;
  flyingTo: google.maps.places.AutocompletePrediction | null;
  tripType: TripTypes;
  tripClass: TripClassTypes;
  purpose: TripPurposeTypes;
  startDate: Date | null;
  endDate: Date | null;
}

const FlightSearchForm: FC<FlightSearchFormProps> = () => {
  const [dropOffLocationType, setDropOffLocationType] = useState<TripTypes>("roundTrip");
  const [flightClassState, setFlightClassState] = useState<TripClassTypes>("Economy");
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);
  const navigate = useNavigate();

  const [tripDetails, setTripDetails] = useState<BusinessTripState>({
    flyingFrom: null,
    flyingTo: null,
    tripType: 'roundTrip',
    tripClass: 'Economy',
    purpose: 'Attending a Conference or Trade Show',
    startDate: new Date(),
    endDate: moment().add(5, 'days').toDate(),
  });

  //
  const refGuestContainer = React.createRef<HTMLDivElement>();
  const refClassContainer = React.createRef<HTMLDivElement>();
  const [openGuest, setOpenGuest] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  useOutsideAlerter(refGuestContainer, () => setOpenGuest(false));
  useOutsideAlerter(refClassContainer, () => setOpenClass(false));

  // Function to handle form submission
  const handleSubmit = async () => {
    const validationErrors = validateTripDetails(tripDetails);

    if (validationErrors.length > 0) {
      const errorMessage = validationErrors.join('\n');
      toast.error(errorMessage)
      console.error(validationErrors);
      return;
    }

    // If validation passes, proceed to send data to backend
    try {
      // Replace with your actual API call
      const { data: itinerary } = await sendTripDetailsToBackend(tripDetails);
      toast.success('Trip details submitted successfully.')
      navigate(`/trip/${itinerary.id}`);
    } catch (error) {
      // Handle submission error
      console.error('Error submitting trip details:', error);
      toast.error('Something went wrong.')
    }
  };

  const flyingFromOnInputdone = (value: google.maps.places.AutocompletePrediction) => {
    setTripDetails(prevDetails => ({ ...prevDetails, flyingFrom: value }));
  }

  const flyingToOnInputdone = (value: google.maps.places.AutocompletePrediction) => {
    setTripDetails(prevDetails => ({ ...prevDetails, flyingTo: value }));
  }

  const onChangeStartDate = (startDate: Date | null) => {
    setTripDetails(prevDetails => ({
      ...prevDetails,
      startDate: startDate
    }));
  };

  const onChangeEndDate = (endDate: Date | null) => {
    setTripDetails(prevDetails => ({
      ...prevDetails,
      endDate: endDate
    }));
  }

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const renderTripPurposeRadio = (
    name: string,
    id: string,
    label: TripPurposeTypes,
    defaultChecked?: boolean
  ) => {
    return (
      <div className="flex items-center mb-4">
        <input
          defaultChecked={tripDetails?.purpose === label}
          id={id + name}
          name={name}
          onChange={() => setTripDetails(prevDetails => ({ ...prevDetails, purpose: label }))}
          type="radio"
          className="focus:ring-primary-500 h-6 w-6 text-primary-500 border-neutral-300 !checked:bg-primary-500 bg-transparent"
        />
        <label
          htmlFor={id + name}
          className="ml-3 block text-sm font-medium text-neutral-700 dark:text-neutral-300"
        >
          {label}
        </label>
      </div>
    );
  };

  const renderPurpose = () => {
    return (
      <div className="">
        <div ref={refGuestContainer} className="relative">
          <>
            <div
              className={`cursor-pointer px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 text-xs`}
              onClick={() => setOpenGuest(!openGuest)}
            >
              <span>Purpose</span>
              <ChevronDownIcon
                className={`${openGuest ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </div>
            <Transition
              show={openGuest}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="absolute z-20 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 left-1/2 -translate-x-1/2  py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl ring-1 ring-black/5 dark:ring-white/10">
                {renderTripPurposeRadio("purpose", "Attending a Conference or Trade Show", "Attending a Conference or Trade Show")}
                {renderTripPurposeRadio("purpose", "Client Meetings", "Client Meetings")}
                {renderTripPurposeRadio("purpose", "Sales and Business Development", "Sales and Business Development")}
                {renderTripPurposeRadio("purpose", "Training and Professional Development", "Training and Professional Development")}
                {renderTripPurposeRadio("purpose", "Internal Company Meetings", "Internal Company Meetings")}
                {renderTripPurposeRadio("purpose", "Market Research", "Market Research")}
                {renderTripPurposeRadio("purpose", "Site Visits and Inspections", "Site Visits and Inspections")}
                {renderTripPurposeRadio("purpose", "Networking Events", "Networking Events")}
                {renderTripPurposeRadio("purpose", "Looking for Funding/Seeking Investment", "Looking for Funding/Seeking Investment")}
                {renderTripPurposeRadio("purpose", "Other", "Other")}
              </div>
            </Transition>
          </>
        </div>
      </div>
    );
  };

  const renderSelectClass = () => {
    return (
      <div className="">
        <div ref={refClassContainer} className="relative">
          <>
            <div
              className={`px-4 py-1.5 rounded-md inline-flex items-center font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-0 text-xs`}
              onClick={() => setOpenClass(!openClass)}
            >
              <span>{`${flightClassState}`}</span>
              <ChevronDownIcon
                className={`${openClass ? "" : "text-opacity-70"
                  } ml-2 h-4 w-4 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </div>
            <Transition
              show={openClass}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <div className="absolute z-30 w-screen max-w-[200px] sm:max-w-[220px] px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10 ">
                  <div className="relative grid bg-white dark:bg-neutral-800 p-3">
                    {flightClass.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => {
                          e.preventDefault();
                          setFlightClassState(item.name);
                          setTripDetails(prevDetails => ({ ...prevDetails, tripClass: item.name }));
                          setOpenClass(false);
                        }}
                        className="flex items-center p-2 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <p className="text-sm font-medium ">{item.name}</p>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </Transition>
          </>
        </div>
      </div>
    );
  };

  const renderRadioBtn = () => {
    return (
      <div className="pb-3 flex justify-center space-x-3">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${dropOffLocationType === "roundTrip"
            ? "bg-black shadow-black/10 shadow-lg text-white"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => {
            setDropOffLocationType("roundTrip")
            setTripDetails(prevDetails => ({ ...prevDetails, tripType: "roundTrip" }));
          }}
        >
          Round-trip
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer select-none ${dropOffLocationType === "oneWay"
            ? "bg-black text-white shadow-black/10 shadow-lg"
            : "border border-neutral-300 dark:border-neutral-700"
            }`}
          onClick={(e) => {
            setDropOffLocationType("oneWay")
            setTripDetails(prevDetails => ({ ...prevDetails, tripType: "oneWay" }));
          }}
        >
          One-way
        </div>

        <div className=" border-r border-slate-200 dark:border-slate-700 "></div>

        <div className="border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderSelectClass()}
        </div>
        <div className="border border-neutral-300 dark:border-neutral-700 rounded-full">
          {renderPurpose()}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <form className="w-full relative ">
        {renderRadioBtn()}
        <div className="flex w-full rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
          <LocationInput
            placeHolder="Departure"
            desc="Where do you fly from?"
            className="flex-1"
            onInputDone={flyingFromOnInputdone}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <LocationInput
            placeHolder="Destination"
            desc="Where you fly to?"
            className="flex-1"
            divHideVerticalLineClass=" -inset-x-0.5"
            onInputDone={flyingToOnInputdone}
          />
          <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
          <FlightDateRangeInput
            selectsRange={dropOffLocationType !== "oneWay"}
            className="flex-1"
            onClick={handleSubmit}
            onChangeStartDate={onChangeStartDate}
            onChangeEndDate={onChangeEndDate}
          />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default FlightSearchForm;
