import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { deleteActivity, fetchActivityDataByActivtyId, handleJoinActivity, leaveActivity } from "api/activities";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import LikeSaveBtns from "components/LikeSaveBtns";
import StartRating from "components/StartRating/StartRating";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import { ActivityResponse, AttendanceStatus } from "interface/activity";
import { observer } from "mobx-react";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { authStore } from "stores/AuthStore";
import ShareAndDelete from "./Buttons/ShareAndDelete";
import ActivityDetailLayout from "./ActivityDetailLayout";
import FooterNav from "components/FooterNav";

const ActivityDetailPageContainer: FC<{}> = observer(() => {
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [activity, setActivity] = useState<ActivityResponse>({} as ActivityResponse);
  const [loading, setLoading] = useState<boolean>(true);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [joinLoading, setJoinLoading] = useState<boolean>(false);
  const { activity_id } = useParams<{ activity_id: string }>(); // Extract the activity ID from the URL
  const { currentUser } = authStore

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const me = currentUser?.id === activity?.author?.id;
  const currentAttendee = activity?.attendees?.find(attendee => attendee?.user?.id === currentUser?.id);
  const userStatus = currentAttendee ? currentAttendee.status : null;
  const going = activity?.attendees?.filter(attendee => attendee.status === AttendanceStatus.GOING).length;
  const interested = activity?.attendees?.filter(attendee => attendee.status === AttendanceStatus.INTERESTED).length;


  // Fetch activity details when component mounts
  useEffect(() => {
    const fetchActivityDetails = async () => {
      if (!activity_id) {
        toast.error("Activity ID is missing from the URL.");
        return;
      }

      try {
        setLoading(true);
        const response = await fetchActivityDataByActivtyId(activity_id);
        console.log("Response:", response);

        if (response.status === 200) {
          setActivity(response.data);
        } else {
          toast.error("Failed to fetch activity details. Please try again later.");
        }
      } catch (error) {
        console.error("Error fetching activity details:", error);
        toast.error("An error occurred while fetching activity details.");
        router("/");
      } finally {
        setLoading(false);
      }
    };

    fetchActivityDetails();
  }, [activity_id]);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };


  const handleJoin = async (activityId: number, status: AttendanceStatus) => {
    try {
      setJoinLoading(true);
      const response = await handleJoinActivity(activityId, status);

      console.log("Response:", response);
      if (response.status === 201) {
        toast.success("Successfully joined the activity!");
        setActivity(response.data);
      } else {
        toast.error("Failed to join the activity. Please try again later.");
      }
    } catch (error) {
      console.error("Error joining the activity:", error);
      toast.error("An error occurred while joining the activity.");
    } finally {
      setJoinLoading(false);
    }
  }

  const handleCancel = async (activityId: number) => {
    try {
      // Custom logic for canceling the activity
      setJoinLoading(true);

      const response = await leaveActivity(activityId)
      if (response.status === 200) {
        toast.success("Successfully canceled the activity!");
        setActivity(response.data);
      } else {
        toast.error("Failed to cancel the activity. Please try again later.");
      }
    } catch (error) {
      console.error("Error canceling the activity:", error);
      toast.error("An error occurred while canceling the activity.");
    } finally {
      setJoinLoading(false);
    }
  }

  const handleDelete = async (activityId: number) => {
    // Custom logic for deleting the activity
    console.log("Activity deleted!");
    try {
      setDeleteLoading(true);
      await deleteActivity(activityId);
    } catch (error) {
      console.error("Error deleting the activity:", error);
    } finally {
      setDeleteLoading(false);
      router("/");
    }
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={activity?.activityType} />
          {me ? <ShareAndDelete onDelete={() => handleDelete(activity?.id)} /> : <LikeSaveBtns />}
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {activity?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{activity?.placeDescription}</span>
          </span>
        </div>

        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-calendar"></i>
            <span className="ml-1">{moment(activity?.startDate).format('ll')} {activity?.startTime}</span>
            {activity?.endDate ? <span className="ml-1">- {moment(activity?.endDate).format('ll')}  {activity?.endTime}</span> : null}
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">{activity?.timezone}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" imgUrl={activity?.author?.profile?.avatar} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Activity by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {activity?.author?.profile?.fullName}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="block lg:hidden flex-grow mt-14 lg:mt-0">
          {renderSidebar()}
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Activity information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300 break-all">
          <span>{activity?.description}</span>
        </div>
      </div>
    );
  };

  // const renderSection3 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       <div>
  //         <h2 className="text-2xl font-semibold">Amenities </h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           {` About the property's amenities and services`}
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* 6 */}
  //       <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
  //         {Amenities_demos.filter((_, i) => i < 12).map((item) => (
  //           <div key={item.name} className="flex items-center space-x-3">
  //             <i className={`text-3xl las ${item.icon}`}></i>
  //             <span className=" ">{item.name}</span>
  //           </div>
  //         ))}
  //       </div>

  //       {/* ----- */}
  //       <div className="w-14 border-b border-neutral-200"></div>
  //       <div>
  //         <ButtonSecondary onClick={openModalAmenities}>
  //           View more 20 amenities
  //         </ButtonSecondary>
  //       </div>
  //       {renderMotalAmenities()}
  //     </div>
  //   );
  // };

  // const renderMotalAmenities = () => {
  //   return (
  //     <Transition appear show={isOpenModalAmenities} as={Fragment}>
  //       <Dialog
  //         as="div"
  //         className="fixed inset-0 z-50 overflow-y-auto"
  //         onClose={closeModalAmenities}
  //       >
  //         <div className="min-h-screen px-4 text-center">
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0"
  //             enterTo="opacity-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100"
  //             leaveTo="opacity-0"
  //           >
  //             <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
  //           </Transition.Child>

  //           {/* This element is to trick the browser into centering the modal contents. */}
  //           <span
  //             className="inline-block h-screen align-middle"
  //             aria-hidden="true"
  //           >
  //             &#8203;
  //           </span>
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 scale-95"
  //             enterTo="opacity-100 scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 scale-100"
  //             leaveTo="opacity-0 scale-95"
  //           >
  //             <div className="inline-block py-8 h-screen w-full max-w-4xl">
  //               <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
  //                 <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
  //                   <h3
  //                     className="text-lg font-medium leading-6 text-gray-900"
  //                     id="headlessui-dialog-title-70"
  //                   >
  //                     Amenities
  //                   </h3>
  //                   <span className="absolute left-3 top-3">
  //                     <ButtonClose onClick={closeModalAmenities} />
  //                   </span>
  //                 </div>
  //                 <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
  //                   {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
  //                     <div
  //                       key={item.name}
  //                       className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
  //                     >
  //                       <i
  //                         className={`text-4xl text-neutral-6000 las ${item.icon}`}
  //                       ></i>
  //                       <span>{item.name}</span>
  //                     </div>
  //                   ))}
  //                 </div>
  //               </div>
  //             </div>
  //           </Transition.Child>
  //         </div>
  //       </Dialog>
  //     </Transition>
  //   );
  // };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            imgUrl={activity?.author?.profile?.avatar}
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              {activity?.author?.profile?.fullName}
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          {activity?.author?.profile?.bio ? activity?.author?.profile?.bio : "No bio"}
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>@{activity?.author?.profile?.role}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>{activity?.author?.profile?.industry}</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>{activity?.author?.profile?.companyName}</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href={`/user/${activity?.author?.id}`}>See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderLocation = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {activity?.placeDescription}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=${activity?.lat},${activity?.long}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl mb-8 p-6 rounded-3xl">
        {/* ACTIVITY INFO */}

        {/* USERS COUNT */}
        <div className="flex flex-row text-center justify-between">
          <span className="block">👥 {going} Going</span>
          <span className="block">⭐ {interested} Interested</span>
        </div>

        {/* CTA BUTTONS */}
        <div className="flex flex-col mt-6 space-y-4">
          {userStatus === AttendanceStatus.GOING || userStatus === AttendanceStatus.INTERESTED ? (
            <ButtonPrimary
              loading={joinLoading}
              className="w-full bg-red-500"
              onClick={() => handleCancel(activity?.id)}
            >
              Leave Activity
            </ButtonPrimary>) :
            (<><ButtonPrimary
              loading={joinLoading}
              disabled={currentUser?.id === activity?.author?.id}
              className={`w-full ${userStatus === AttendanceStatus.GOING ? 'bg-green-500' : ''}`}
              onClick={() => handleJoin(activity?.id, AttendanceStatus.GOING)}
            >
              {userStatus === AttendanceStatus.GOING ? 'You are Going' : 'Join Activity'}
            </ButtonPrimary>

              <ButtonPrimary
                loading={joinLoading}
                disabled={currentUser?.id === activity?.author?.id}
                onClick={() => handleJoin(activity?.id, AttendanceStatus.INTERESTED)}
                className={`w-full ${userStatus === AttendanceStatus.INTERESTED ? 'bg-yellow-500' : ''}`}
              >
                {userStatus === AttendanceStatus.INTERESTED ? 'You are Interested' : 'Interested'}
              </ButtonPrimary></>)
          }
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative flex flex-wrap gap-1 sm:gap-2">
          <div
            className="relative w-full h-64 sm:h-80 rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute object-cover rounded-md sm:rounded-xl w-full h-full"
              src={activity?.image}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
        </div>
      </header>



      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-4 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {/* {renderSection3()} */}
          {/* {renderSection4()} */}
          {/* <SectionDateRange /> */}
          {renderSection5()}
          {/* {renderSection6()}
          {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">
            {renderSidebar()}
            {renderLocation()}
          </div>
        </div>
      </main>
      <FooterNav />
    </div>
  );
})

export default function ActivityDetailPage() {
  return (
    <ActivityDetailLayout>
      <ActivityDetailPageContainer />
    </ActivityDetailLayout>
  );
}
