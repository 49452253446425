import OnBoardingStep1 from 'containers/OnBoarding/OnBoardingStep1';
import OnBoardingStep2 from 'containers/OnBoarding/OnBoardingStep2';
import OnBoardingStep3 from 'containers/OnBoarding/OnBoardingStep3';
import OnBoardingStep4 from 'containers/OnBoarding/OnBoardingStep4';
import { observer } from 'mobx-react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { authStore } from 'stores/AuthStore';
import { onboardingStore } from 'stores/OnboardingStore';

interface ProtectedRouteProps {
  path: string;
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = observer(({ path, component: Component }) => {

  if (!authStore?.loading && !authStore?.currentUser) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" replace />;
  }

  if (!authStore?.currentUser) {
    return null
  }
  // Check if the user needs to complete onboarding
  if (!authStore?.currentUser?.profile.hasCompletedOnboarding) {
    // if (onboardingStore.onBoardingStep === 1) return <SectionHowItWork className='mt-10 h-auto' />
    if (onboardingStore.onBoardingStep === 1) return <OnBoardingStep1 />
    if (onboardingStore.onBoardingStep === 2) return <OnBoardingStep2 />
    if (onboardingStore.onBoardingStep === 3) return <OnBoardingStep3 />
    if (onboardingStore.onBoardingStep === 4) return <OnBoardingStep4 />
  }

  // Render the component if authenticated
  return <Component user={authStore?.currentUser} />;
})

export default ProtectedRoute