import axios from "axios";

export const sendLinkedInCodeToBackend = async (code: string, state: string) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/authentication/linkedin/callback`, 
            { code, state }, 
            {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', // Optional: Set if not automatically added by Axios
                }
            }
        );        // Handle success (e.g., store user info, token, etc.)
        console.log('Success:', response.data);
        // Navigate to a different page, e.g., dashboard
        return response.data;
    } catch (error) {
        // Handle error
        console.error('Error:', error);
        // Redirect to an error page or show a message
        throw error;
    }
};

export const logout = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/authentication/logout`, {}, { withCredentials: true });
        return response.data;
    } catch (error) {
        // Handle error
        console.error('Error:', error);
        throw error;
    }
};