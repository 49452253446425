import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authStore } from "stores/AuthStore";

const MagicLinkPage = () => {
  const { isSignInWithEmailLinkHandler, signInWithEmailLinkHandler } = authStore;

  const [signInError, setSignInError] = useState(false);
  const navigate = useNavigate()

  const handleSignInLink = async () => {
    try {
      const email = window.localStorage.getItem('emailForSignIn');
      console.log('The email for sign in:', email);
      if (!email) {
        navigate('/login')
        return;
      }
      const isValidUrl = isSignInWithEmailLinkHandler(window.location.href)
      console.log('Is valid url', isValidUrl);

      if (isValidUrl) {
        const result = await signInWithEmailLinkHandler(email, window.location.href);
        if (result) {
          navigate('/');
        } else {
          navigate('login')
        }
      }
    } catch (error) {
      console.error(error);
      setSignInError(true);
    }
  }

  useEffect(() => {
    handleSignInLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex items-center justify-center h-screen">
      {!signInError ? (
        <p className="text-center">The link is not valid.</p>
      ) : (
        <p className="text-center">Validating link...</p>
      )}
    </div>
  );
};

export default MagicLinkPage;
