import React, { FC, useState } from 'react';
import { useDropzone } from 'react-dropzone';


interface DropZoneProps {
    onFileSelect: (file: File | null) => void;
}

const DropZone: FC<DropZoneProps> = ({ onFileSelect }: DropZoneProps) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const onDrop = (acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSelectedFile(file);
            onFileSelect(file); // Call parent callback with the selected file
        } else {
            setSelectedFile(null);
            onFileSelect(null); // Call parent callback with null if no file is selected
        }
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/gif': ['.gif']
        }, maxSize: 10 * 1024 * 1024, // 10MB
    });

    return (
        <div
            {...getRootProps()}
            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-md cursor-pointer"
        >
            <input {...getInputProps()} />
            <div className="space-y-1 text-center">
                <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                >
                    <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                    <p className="pl-1">
                        {isDragActive ? "Drop the file here..." : "Upload a file or drag and drop"}
                    </p>
                </div>
                <p className="text-xs text-neutral-500 dark:text-neutral-400">
                    PNG, JPG, GIF up to 10MB
                </p>
            </div>
            {selectedFile && (
                <div className="mt-2 text-sm text-neutral-600 dark:text-neutral-300">
                    <p>Selected file: {selectedFile.name}</p>
                </div>
            )}
        </div>
    );
};

export default DropZone;
