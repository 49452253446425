import { getMatches, submitUserMatchInteraction } from "api/matches";
import FooterNav from "components/FooterNav";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import swipeLottie from "../../images/lottie/swipe.json";
import 'swiper/css';

import Avatar from "shared/Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const MeetWithPage: React.FC = () => {
    const cardRef = useRef(null);
    const navigate = useNavigate();

    const [matches, setMatches] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [showLottie, setShowLottie] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLottie(false);
        }, 4000);

        return () => clearTimeout(timer); // Clean up the timer if the component unmounts
    }, []);


    useEffect(() => {
        const fetchMatches = async () => {
            setLoading(true);
            try {
                const matches = await getMatches();
                setMatches(matches.data.items);
            } catch (error) {
                console.error('Failed to fetch matches:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, []);

    const handleUserMatchAction = async (userIndex: number, action: 'L' | 'D'): Promise<boolean> => {
        const targetUser = matches[userIndex];

        if (!targetUser) {
            console.error('No user found at the provided index');
            return false
        }

        try {
            const { data } = await submitUserMatchInteraction(targetUser.id, action)
            if (data) {
                navigate(`/user/${targetUser.id}`);
            }
        } catch (error) {
            console.error('An error occurred while sending the user action to the backend:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            return false;
        }
    };


    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isSwiping) return;

        const currentX = e.touches[0].clientX;
        const diffX = currentX - startX;

        setCurrentTranslate(diffX);
    };

    const handleTouchEnd = () => {
        setIsSwiping(false);

        if (currentTranslate > 100) {
            handleSwipe('left');
        } else if (currentTranslate < -100) {
            handleSwipe('right');
        } else {
            setCurrentTranslate(0);
        }
    };

    const handleSwipe = async (direction: string) => {
        const person = matches[currentIndex];

        if (direction === 'right') {
            await handleUserMatchAction(currentIndex, 'L');
        } else if (direction === 'left') {
            await handleUserMatchAction(currentIndex, 'D');
        }
        setMatches(prevMatches => prevMatches.filter((people) => people.id !== person.id));
        setCurrentIndex((prevIndex) => 0);
        setCurrentTranslate(0);
    };


    if (currentIndex >= matches.length) {
        return <div className="text-center mt-20">No more matches</div>;
    }

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: swipeLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="nc-MeetWithPage">
            <Helmet>
                <title>Meet With Page</title>
            </Helmet>
            <div className="container overflow-y-auto pb-20"> {/* Add pb-20 for padding at the bottom */}
                {/* HEADER */}
                {loading ? (
                    <BeatLoader color="#123abc" />
                ) : (
                    <div className="flex flex-col">
                        {showLottie && (
                            <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                <div className="p-6 rounded-lg text-white text-center">
                                    <Lottie options={lottieOptions} height={100} width={200} />
                                    <p className="mt-4 text-lg">
                                        Swipe right to accept, or left to pass on this opportunity.
                                    </p>
                                </div>
                            </div>
                        )}
                        <div
                            className=""
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            {matches.map((person, index) => (
                                <div
                                    key={person.id}
                                    ref={cardRef}
                                    className={` inset-0 transition-transform duration-300 ease-in-out ${index === currentIndex ? 'block' : 'hidden'
                                        }`}
                                    style={{
                                        transform: `translateX(${index === currentIndex ? currentTranslate : 0}px)`,
                                    }}
                                >
                                    <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
                                        <div className="flex flex-col items-center">
                                            <Avatar
                                                imgUrl={person.profile.avatar}
                                                userName={person.profile.fullName}
                                                sizeClass="w-32 h-32 rounded-full object-cover mb-4"
                                            />
                                            <h3 className="text-xl font-semibold">
                                                {person.profile.fullName}
                                            </h3>
                                            <p className="text-gray-500 mb-2">
                                                {person.profile.role} at {person.profile.companyName}
                                            </p>
                                            <p className="text-gray-500 mb-2">
                                                {person.profile.industry}
                                            </p>
                                            <p className="text-gray-400">
                                                {person.profile.city}, {person.profile.country}
                                            </p>
                                            <p className="text-sm mt-4 text-center">
                                                {person.profile.bio}
                                            </p>
                                        </div>

                                        <div className="mt-6">
                                            <h4 className="text-lg font-semibold">Interests</h4>
                                            <ul className="list-none text-sm text-gray-600 mt-2 flex flex-wrap gap-2">
                                                {person?.profile?.interests?.map((interest: string, i: number) => (
                                                    <li
                                                        key={i}
                                                        className="inline-block px-3 py-1 bg-gray-200 text-gray-800 rounded-full"
                                                    >
                                                        {interest}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className="mt-6">
                                            <h4 className="text-lg font-semibold">Languages</h4>
                                            <ul className="list-none text-sm text-gray-600 mt-2 flex flex-wrap gap-2">
                                                {person?.profile?.languages?.map((language: string, i: number) => (
                                                    <li
                                                        key={i}
                                                        className="inline-block px-3 py-1 bg-gray-200 text-gray-800 rounded-full"
                                                    >
                                                        {language}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <FooterNav /> {/* Make sure this is at the bottom of the page */}
        </div>

    );
};

export default MeetWithPage;
