import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { FC } from "react";
import { Helmet } from "react-helmet";
import SectionEventsFilterCard from "./SectionEventsFilterCard";
import FooterNav from "components/FooterNav";

export interface MoreEventsPageProps {
  className?: string;
}

const MoreEventsPage: FC<MoreEventsPageProps> = ({ className = "" }) => {
    

  return (
    <div
      className={`nc-MoreEventsPageProps relative overflow-hidden ${className}`}
      data-nc-id="MoreEventsPageProps"
    >
      <Helmet>
        <title>Meet-With</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Stays"
          currentTab="Stays"
          className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
        /> */}

        {/* SECTION */}
        <SectionEventsFilterCard className="pb-24 lg:pb-28 mt-4"/>

        {/* SECTION 1 */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingStayMapPage"
          />
        </div> */}

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}

        {/* SECTION */}
        {/* <div className="relative py-16 mb-24 lg:mb-28">
          <BackgroundSection className="bg-orange-50 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox />
        </div> */}
      </div>
      <FooterNav/>
    </div>
  );
};

export default MoreEventsPage;
