import CardLikeMindedLocalsBox from "components/CardLikeMindedLocalsBox/CardLikeMindedLocalsBox";
import useNcId from "hooks/useNcId";
import { FC } from "react";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
export interface SectionSliderDiscoverLocalsProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  likeMindedLocals?: any[];
  itemPerRow?: 4 | 5;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  uniqueClassName: string;
}


const SectionSliderDiscoverLocals: FC<SectionSliderDiscoverLocalsProps> = ({
  heading = "Heading of sections",
  subHeading = "Descriptions for sections",
  className = "",
  itemClassName = "",
  likeMindedLocals = [],
  itemPerRow = 5,
  hasNextPrev = true,
  isCenter = false,
  uniqueClassName,
}) => {
  const UNIQUE_CLASS = "LocalsSectionSlider" + uniqueClassName + useNcId();

  return (
    <div className={`nc-LocalsSectionSlider ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Swiper 
        className="w-full h-full mySwiper"
        slidesPerView={1.5}
        spaceBetween={30}
        breakpoints={ {
          1280: {
            slidesPerView: itemPerRow - 1,
          },
          1024: {
            spaceBetween: 20,
            slidesPerView: itemPerRow - 1,
          },
          768: {
            spaceBetween: 20,
            slidesPerView: itemPerRow - 2,
          },
          640: {
            spaceBetween: 20,
            slidesPerView: itemPerRow - 3,
          },
          500: {
            spaceBetween: 20,
            slidesPerView: 1.5,
          },
        }}
        >
        {likeMindedLocals.map((local, index) => (
          <SwiperSlide key={index} className="flex justify-center items-center text-center text-lg">
            <CardLikeMindedLocalsBox index={index + 1} likeMindedLocal={local} />
          </SwiperSlide>
        ))}
        </Swiper>
        {/* {hasNextPrev && (
          <NextPrev className="justify-center mt-8" />
        )} */}

        {/* <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5"> */}
        {/* <ButtonSecondary>Show me more </ButtonSecondary> */}
        {/* <ButtonPrimary>Become a host</ButtonPrimary> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default SectionSliderDiscoverLocals;
