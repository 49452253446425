import { AxiosResponse } from "axios";
import axiosInstance from "utils/setAuthorizationHeader";
export interface ContactUsEmailBody {
    name: string;
    email: string;
    message: string;
}

export async function sendContactUsEmail(contactUsEmailBody: ContactUsEmailBody): Promise<AxiosResponse<any>> {
    const url = `${process.env.REACT_APP_API_URL}/email/contact-us`
    return axiosInstance.post(url, contactUsEmailBody, { withCredentials: true })
}
