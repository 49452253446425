import { getEvents, getNearByEventForTripId } from 'api/explore';
import { EventResponse, ITransformedEvent } from 'data/types';
import { makeAutoObservable, runInAction } from 'mobx';

export interface IEventQueryParameters {
    country: string;
    start: string;
    end: string;
    page: string;
    limit: string;
}


class EventStore {
    events: ITransformedEvent[] = [];
    isLoading = false;
    error = null;
    hasMore = true;
    currentPage = 1;
    queryParameters: IEventQueryParameters = {
        country: '',
        start: '',
        end: '',
        page: '1',
        limit: '10'
    };

    constructor() {
        makeAutoObservable(this);
    }

    async fetchEvents(params?: Partial<IEventQueryParameters>) {
        if (!this.hasMore) return;
    
        this.isLoading = true;
        this.error = null;
    
        if (params) {
          this.queryParameters = { ...this.queryParameters, ...params, page: this.currentPage.toString() };
        }
    
        try {
          const response = await getEvents(this.queryParameters);
          runInAction(() => {
            const data: EventResponse = response.data;
            this.events = [...this.events, ...data.data];
            this.currentPage += 1;
            this.hasMore = this.currentPage <= data.meta.totalPages;
            this.isLoading = false;
          });
        } catch (error: any) {
          runInAction(() => {
            this.error = error.message;
            this.isLoading = false;
          });
        }
      }

    async fetchEventsByTripId(tripId: string | null = null) { // Default API endpoint
        this.isLoading = true;
        this.error = null;
        this.events = [];
        try {
            const response = await getNearByEventForTripId(tripId);
            runInAction(() => {
                this.events = [...this.events, ...response.data.data]; // Assuming the response has a 'results' field
                this.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
            });
        }
    }

    resetStore() {
        runInAction(() => {
          this.events = [];
          this.isLoading = false;
          this.error = null;
          this.currentPage = 1;
          this.hasMore = true;
          this.queryParameters = {
            country: '',
            start: '',
            end: '',
            page: '1',
            limit: '10'
          };
        });
      }
}

const eventStore = new EventStore();
export default eventStore;
